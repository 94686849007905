/**
 * Renders the LogForm component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.form - The form type.
 * @param {Object} props.formInput - The form input data.
 * @param {boolean} props.changes_made - Indicates if changes have been made.
 * @returns {JSX.Element} The rendered LogForm component.
 * @author Jackson Kupris
 */

/**
 * TODO: Test all logs - Test saving logs and editing again - Test having multiple saved - Test submitting without refreshing
 * TODO: Create/Modify a table that can handle the new values being passed to it. Will need additional columns.
 */

import React, { useEffect, useState } from 'react';
import '../StyleSheets/Forms.css';
import Spinner from '../components/Spinner';
import { DateToYMD, FormatLocalTimeTwentyFourNoSeconds } from '../imports/API/TextFormatingFunctions';

const LogForm = (props) => {
	const { form, formInput, changes_made, SavedLogs } = props;
	const [logType, setLogType] = useState({});
	const [savedForm, setSavedForm] = useState(SavedLogs ? SavedLogs : null);
	const [optionData, setOptionData] = useState([]);
	const [optionData2, setOptionData2] = useState([]);
	const [fields, setFields] = useState([]);
	const [loading, setLoading] = useState(true);
	const [renderSubOption, setRenderSubOption] = useState([]);
	const [renderSubOption2, setRenderSubOption2] = useState([]);
	const currentYear = new Date();
	const stickerYears = [
		{ id: 0, title: `${currentYear.getFullYear()}` },
		{ id: 1, title: `${currentYear.getFullYear() + 1}` },
		{ id: 2, title: `${currentYear.getFullYear() + 2}` },
	];

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			const fetchLogType = async () => {
				const logTypeData = await selectCallLogOptions();
				setLogType(logTypeData);
				return logTypeData;
			};

			const fetchOptionData = async () => {
				const logInfoData = await getLogInformation();
				return logInfoData;
			};

			const initializeData = async () => {
				await fetchLogType();
				await fetchOptionData();
				setLoading(false);
			};

			initializeData();
		}
		return () => {
			mounted = false;
		};
	}, [loading]);

	const logTypes = {
		CustomerLog: 'customer-log',
		StoreLog: 'store-log',
		TechLog: 'tech-log',
		EOS: 'eos',
		Bug: 'bug-report',
		serviceAlarm: 'service-alarm-report',
		locationRequest: 'location-request',
	};

	/**
	 * Cleans the options array by removing any elements with null or undefined id values,
	 * and recursively cleans the sub and tertiary options arrays.
	 *
	 * @param {Array} options - The options array to be cleaned.
	 * @returns {Array} - The cleaned options array.
	 * @author Jackson Kupris
	 */
	function cleanOptions(options) {
		return options
			.filter((option) => option.id !== null && option.id !== undefined)
			.map((option) => {
				if (option.sub) {
					option.sub = cleanOptions(option.sub);
				}
				if (option.tertiary) {
					option.tertiary = cleanOptions(option.tertiary);
				}
				return option;
			});
	}

	async function logTypeHandler() {
		const selectedLogType = logTypes[form] || {};
		return selectedLogType;
	}

	async function selectCallLogOptions() {
		return new Promise((resolve, reject) => {
			socket.emit('selectCallLogOptions', async (res) => {
				if (!res) {
					console.error('Logs.selectCallLogOptions: There was an issue calling this method');
					reject('Error fetching log options');
				} else {
					const selectedLog = await logTypeHandler();
					const foundArray = res.find((array) => array.some((el) => el.log_id === selectedLog));
					const foundLog = foundArray ? foundArray.filter((el) => el.log_id === selectedLog) : null;
					const logType = foundLog ? foundLog[0].log_id : null;
					const logDisplayName = foundLog ? foundLog[0].log_display_name : null;
					const callLogObj = {
						id: logType,
						display_name: logDisplayName,
						fields: [],
						options: {},
						socket: [],
					};
					if (foundLog) {
						foundLog.forEach((el) => {
							// Populate fields
							if (!callLogObj.fields.some((field) => field.id === el.field_id)) {
								callLogObj.fields.push({
									log_id: el.log_id,
									index: el.field_index,
									tab: el.field_tab,
									id: el.field_id,
									required: el.required_field,
									label: el.field_label,
									type: el.field_type,
									input_type: el.field_input_type,
									placeholder: el.field_placeholder,
								});
								if (el.socket) {
									const socketString = el.socket || '';
									const socketArray = JSON.parse(socketString.replace(/'/g, '"')) || '';
									callLogObj.socket = socketArray;
								}
							}
							// Populate options
							if (!callLogObj.options[el.field_id]) {
								callLogObj.options[el.field_id] = [];
							}
							let parentOption = callLogObj.options[el.field_id].find((option) => option.id === el.option_id);
							if (!parentOption) {
								parentOption = {
									id: el.option_id,
									title: el.option_title,
									tab: el.option_tab,
									altLabel: el.option_altLabel,
									sub: [],
								};
								callLogObj.options[el.field_id].push(parentOption);
							}
							if (el.sub_option_id) {
								let subOption = parentOption.sub.find((sub) => sub.id === el.sub_option_id);
								if (!subOption) {
									subOption = {
										id: el.sub_option_id,
										sid: el.sub_option_sid,
										title: el.sub_option_title,
										tertiary: [],
										multi: el.multi_field,
									};
									parentOption.sub.push(subOption);
								}
								if (el.tertiary_option_id) {
									let tertiaryOption = subOption.tertiary.find((tertiary) => tertiary.id === el.tertiary_option_id);
									if (!tertiaryOption) {
										tertiaryOption = {
											id: el.tertiary_option_id,
											sid: el.tertiary_option_sid,
											title: el.tertiary_option_title,
										};
										subOption.tertiary.push(tertiaryOption);
									}
								}
							}
						});
						// Clean the options array
						callLogObj.options = Object.keys(callLogObj.options).reduce((acc, key) => {
							acc[key] = cleanOptions(callLogObj.options[key]);
							return acc;
						}, {});
						setLogType(callLogObj);
						setFields(callLogObj.fields);
					} else {
						setFields(null);
					}
					resolve(callLogObj);
				}
			});
		});
	}

	function emitSocketEvent(socketName, callback) {
		socket.emit(socketName, (res) => {
			if (!res) {
				console.error(`Logs.${socketName}: There was an issue calling this method`);
			} else {
				callback(res);
				setLoading(false);
			}
		});
	}

	async function getLogInformation() {
		if (logType.socket) {
			if (logType.socket.length === 1) {
				emitSocketEvent(logType.socket[0], setOptionData);
			} else if (logType.socket.length > 1) {
				emitSocketEvent(logType.socket[0], setOptionData);
				emitSocketEvent(logType.socket[1], setOptionData2);
			}
		} else {
			setLoading(false);
		}
	}

	/**
	 * Renders the options for a select element based on the provided options.
	 *
	 * @param {Array} options - The array of options to render.
	 * @returns {Array} - The array of rendered option elements.
	 * @author Jackson Kupris
	 */
	const renderOptions = (options) => {
		const opts = [<option key='' value=''></option>];

		if (options[0].title === 'RENDERED') {
			if (options[1].title === 'KIOSKS') {
				opts.push(<option key={0} value={`No Location`}>{`No Location`}</option>);
				optionData.map((el) => {
					opts.push(
						<option key={el.KioskID} value={`${el.KioskID} ${el.ServerID}`}>{`${el.KioskID} ${el.ServerID}`}</option>
					);
				});
			} else if (options[1].title === 'TECHS') {
				optionData2.map((el) => {
					opts.push(
						<option
							key={el.TechnicianID}
							value={`${el.FirstName} ${el.LastName}`}
						>{`${el.FirstName} ${el.LastName}`}</option>
					);
				});
			}
		} else {
			options.map((el) => {
				opts.push(
					<option key={el.id} value={el.title}>
						{el.title}
					</option>
				);
			});
		}

		return opts;
	};

	/**
	 * Handles the selection of an option in the logs component.
	 *
	 * @param {Event} event - The event object triggered by the option selection.
	 * @author Jackson Kupris
	 */
	const onOptionSelect = (event) => {
		const val = event.target.value;
		const id = event.target.id;
		const options = logType.options[id];
		const selected = options.find((opt) => opt.title.toUpperCase() === val.toUpperCase());

		if (selected && selected.sub && selected.sub.length) {
			const copy = JSON.parse(JSON.stringify(renderSubOption)); // make deep copy
			const idx = copy.parent && copy.parent.length ? 0 : -1;

			if (idx >= 0) {
				// sub option is already displayed, update
				copy.child = selected.id;
				copy.options = selected.sub;

				setRenderSubOption(copy);
				if (!selected.sub.tertiary) {
					setRenderSubOption2([]);
				}
			} else {
				// sub option is not displayed, add
				setRenderSubOption((prevState) => ({
					...prevState.renderSubOption,
					parent: id,
					child: selected.id,
					options: selected.sub,
					altLabel: selected.altLabel,
				}));
				if (!selected.sub.tertiary) {
					setRenderSubOption2([]);
				}
			}
		} else {
			// Set the parent and children fields back to empty fields.
			setRenderSubOption([]);
			setRenderSubOption2([]);
		}
	};

	const onOptionSelect2 = (event) => {
		const parent = event.target.id.split('-');
		const val = event.target.value;
		const _child =
			(parent[3] === 'none' || !_secondary) && parseInt(parent[1]) >= 0
				? renderSubOption.child
				: logType.options[parent[0]].find((opt) => opt.title.toUpperCase() === parent[2].trim().toUpperCase()).id;
		const selected = logType.options[parent[0]][_child].sub.find(
			(opt) => opt.title.toUpperCase() === val.toUpperCase()
		);
		const id = event.target.id;

		if (selected.tertiary && selected.tertiary.length) {
			setRenderSubOption2({
				parent: parent[0],
				child: selected.tertiary,
				options: selected.tertiary,
			});
		} else {
			setRenderSubOption2([]);
		}
	};

	/**
	 * Represents the index of the renderSubOption parent.
	 * If renderSubOption parent exists and has a length greater than 0, idx is set to 0.
	 * Otherwise, idx is set to -1.
	 * @type {number}
	 * @author Jackson Kupris
	 */
	const renderSubOptions = (_parent, tab, _main, _secondary) => {
		const idx = renderSubOption.parent && renderSubOption.parent.length ? 0 : -1;
		const checkbox = idx >= 0 && renderSubOption.options[0].type === 'checkbox' ? true : false;
		const [secondary, tertiary] = _secondary ? _secondary.split('-') : [null, null];
		const subOpt = _secondary
			? logType.options[_parent].find((el) => el.title.toUpperCase() === _main.trim().toUpperCase())
			: null;

		return (
			<div className='flex-column'>
				{!checkbox ? (
					<select
						className='form-input'
						defaultValue={secondary ? secondary.trim() : ''}
						tabIndex={tab}
						key={`${_parent}-${idx}-${_main}-${secondary ? secondary.trim() : 'none'}`}
						id={`${_parent}-${idx}-${_main}-${secondary ? secondary.trim() : 'none'}`}
						onChange={onOptionSelect2}
					>
						{_secondary && idx < 0
							? subOpt.sub.map((opt) => (
									<option key={opt.id} value={opt.title}>
										{opt.title}
									</option>
							  ))
							: renderSubOption.options.map((opt) => (
									<option key={opt.id} value={opt.title}>
										{opt.title}
									</option>
							  ))}
					</select>
				) : (
					<div className='flex-column' style={{ width: '176px' }}>
						<label>Select All That Apply</label>
						<div className='flex-align-center flex-wrap'>
							{renderSubOption.options.map((opt) => (
								<div key={opt.id} className='flex-align-center flex-wrap'>
									<label>{opt.title}</label>
									<input
										className='form-input'
										name={opt.title}
										type='checkbox'
										style={{ width: 'auto', height: 'auto' }}
									></input>
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		);
	};

	const renderSub2Options = (_secondary) => {
		const [secondary, tertiary] = _secondary ? _secondary.split('-') : [null, null];

		return (
			<div className='flex-column'>
				<select className='form-input' id='option3' defaultValue={tertiary ? tertiary.trim() : ''}>
					{renderSubOption2.options.map((opt) => (
						<option key={opt.id} value={opt.title}>
							{opt.title}
						</option>
					))}
				</select>
			</div>
		);
	};

	/**
	 * Renders a form element based on the provided configuration.
	 *
	 * @param {Object} el - The form element configuration.
	 * @returns {JSX.Element} - The rendered form element.
	 */
	const renderFormElement = (el) => {
		const multi_opt =
			logType.options && logType.options[el.id] && logType.options[el.id].length && logType.options[el.id][0].multi
				? true
				: false;

		const [main, secondary] =
			savedForm && multi_opt && !renderSubOption.length
				? savedForm.fields[el.index].value.split('-')
				: savedForm && renderSubOption.length && multi_opt && !renderSubOption.find((opt) => opt.parent == el.id)
				? savedForm.fields[el.index].value.split('-')
				: [null, null];

		return (
			<div
				key={el.id}
				className={
					el.type === 'textarea' || (el.type === 'input' && el.input_type === 'checkbox')
						? 'form-block grid-exempt'
						: 'form-block'
				}
				style={multi_opt ? { height: 210 } : null}
			>
				{el.type == 'select' ? (
					<div>
						<label>{el.label}</label>
						{el.required ? <label className='required_field'>&#42;</label> : null}
						<br></br>
						<select
							tabIndex={el.tab}
							id={el.id}
							defaultValue={
								savedForm && main && secondary
									? main.trim()
									: savedForm && !main
									? savedForm.fields[el.index].value
									: ''
							}
							name='form-input'
							onChange={(e) => {
								changes_made();
								onOptionSelect(e);
							}}
						>
							{renderOptions(logType.options[el.id])}
						</select>
						<div>
							{(Object.values(renderSubOption).length && renderSubOption.parent == el.id) || secondary
								? renderSubOptions(el.id, el.tab, main, secondary)
								: null}
							{(Object.values(renderSubOption2).length && renderSubOption2.parent == el.id) || secondary
								? renderSub2Options(el.id, el.tab, main, secondary)
								: null}
						</div>
					</div>
				) : el.type === 'datalist' ? (
					<div>
						<label>{el.label}</label>
						{el.required ? <label className='required_field'>&#42;</label> : null}
						<br></br>
						<input
							tabIndex={el.tab}
							id={el.id}
							list={el.label}
							name='form-input'
							defaultValue={savedForm ? savedForm.fields[el.index].value : null}
							placeholder={el.placeholder ? el.placeholder : ''}
							onChange={() => changes_made()}
						></input>
						<datalist id={el.label}>{renderOptions(logType.options[el.id])}</datalist>
					</div>
				) : el.type === 'input' && el.input_type !== 'checkbox' ? (
					<div>
						<label>{el.label}</label>
						{el.required ? <label className='required_field'>&#42;</label> : null}
						<br></br>
						<input
							tabIndex={el.tab}
							id={el.id}
							name='form-input'
							maxLength={el.max_length ? el.max_length : null}
							defaultValue={savedForm ? savedForm.fields[el.index].value : el.default_val ? el.default_val : null}
							placeholder={el.placeholder ? el.placeholder : ''}
							type={el.input_type}
							onChange={() => changes_made()}
						></input>
					</div>
				) : el.type === 'input' && el.input_type === 'checkbox' ? (
					<div className='flex-align-center grid-exempt ModalFlexColoumn'>
						<input
							style={{ width: 12, height: 12 }}
							tabIndex={el.tab}
							id={el.id}
							name='form-input'
							defaultChecked={
								savedForm && savedForm.fields && savedForm.fields[el.index] && savedForm.fields.length
									? savedForm.fields[el.index].value
									: false
							}
							type={el.input_type}
							onChange={() => changes_made()}
						></input>
						<label>{el.label}</label>
					</div>
				) : el.type === 'textarea' ? (
					<div className='grid-exempt'>
						<label>{el.label}</label>
						{el.required ? <label className='required_field'>&#42;</label> : null}
						<textarea
							tabIndex={el.tab}
							id={el.id}
							name='form-input'
							defaultChecked={
								savedForm && savedForm.fields && savedForm.fields[el.index] && savedForm.fields.length
									? savedForm.fields[el.index].value
									: false
							}
							placeholder={el.placeholder ? el.placeholder : ''}
							onChange={() => changes_made()}
						></textarea>
					</div>
				) : null}
			</div>
		);
	};

	const renderFormBody = () => {
		// Ensure allFields is always an array
		const allFields = logType.fields || [];
		const formElements = allFields.map((el) => renderFormElement(el));
		return (
			<div className='log-inner-container log-grid-columns' id='log-inner-container'>
				{formElements}
			</div>
		);
	};
	return <div id='log-container'>{loading ? <Spinner margin='chat-margin'></Spinner> : renderFormBody()}</div>;
};

export default LogForm;
