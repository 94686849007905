import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import {
  DateToLocale,
  DateToMDY,
  DateToYMD,
  FormatDate,
  FormatDateToYMD,
  FormatShortDate,
  parseForLabels,
  ParsePaperForAllLabels,
  RenderPaperJobsForPDF,
  SeparateJobs,
} from '../API/TextFormatingFunctions.js';

import { CurrentTime } from '../API/Moment';

import { eolProjection, EOLTransactions, getPaperProjections, getStickerEOL } from './PaperUtil';

import CustomTable from '../../components/Table';
import Button from '@mui/material/Button';
import { FiDownload } from 'react-icons/fi';
import AlertBar from '../../components/AlertBar';
import IconButton from '@mui/material/IconButton';
import { MdMoreVert } from 'react-icons/md';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '../../components/Modal';
import { MdCheckCircleOutline } from 'react-icons/md';
import { MdCheck } from 'react-icons/md';
import TextField from '@mui/material/TextField';
import FileUpload from '../../components/FileUpload';
import { MdCloudUpload } from 'react-icons/md';
import LinearProgress from '@mui/material/LinearProgress';
import '../../StyleSheets/PaperChange.css';
import { useCustomContext } from '../../hoc/Context.js';
import { CSVLink } from 'react-csv';
import Spinner from '../../components/Spinner.js';
import { MdPrint } from 'react-icons/md';
import { MdDescription } from 'react-icons/md';
import { getEmailSignature, getReminderMsg } from '../../utilities/util';
import axios from 'axios';
import env from '../../../env.config.json' assert { type: 'json' };

const FILE_HEADERS = [
  'Kiosk ID', //0
  'Tech ID', //1
  'Proposed Due Date', //2
  'Install Star', //3
  'Star to Ship', //4
  'DM1 Year', //5
  'Pull DM1', //6
  'Fixed Install DM1', //7
  'DM2 Year', //8
  'Pull DM2', //9
  'Estimate Install DM2', //10
  'Estimate Install DM1', //11
  'Fixed Install DM2', //12
];
const YEARS = 5;
const DEFAULT_RUNOUT = 999;
const FULL_SHEET = 10;
const DEFAULT_INCREASE = 0.08;
const STAR_ROLL = env.STAR_ROLL;
const BASE_URL = 'https://s3.us-west-2.amazonaws.com/rti.images/uploaded-paper-changes/';
const TEST_PRINTS = env.TEST_PRINTS;

const TableHeaders = [
  {
    id: 'created',
    name: 'Created',
    sortable: true,
    numeric: false,
  },
  { id: 'kiosk', name: 'Kiosk', sortable: true, numeric: false },
  { id: 'job', name: 'Job #', sortable: true, numeric: false },
  { id: 'paper', name: 'Paper' },
  { id: 'paper-sent', name: 'Sent', sortable: true, numeric: true },
  { id: 'avg', name: 'Daily Avg', sortable: true, numeric: true },
  { id: 'runout', name: 'Runout (Days)', sortable: true, numeric: false },
  { id: 'tech', name: 'Technician', sortable: true, numeric: false },
  { id: 'due-date', name: 'Due Date' },
  { id: 'eta', name: 'ETA', sortable: true, numeric: false },
  { id: 'confirmed', name: 'Confirmed' },
  { id: '_blank', name: '', size: 'xsmall-header' },
];

const TableHeaders2 = [
  { id: 'select', name: '', size: 'xsmall-header', type: 'checkbox' },
  { id: 'job-id', name: 'job #', sortable: true, numeric: false },
  { id: 'upload', name: 'Uploaded', sortable: true, numeric: false },
  { id: 'kiosk', name: 'Kiosk', sortable: true, numeric: false },
  { id: 'tech', name: 'Technician', sortable: true, numeric: false },
  { id: 'paper', name: 'Paper' },
  { id: 'qty', name: 'Qty', sortable: true, numeric: true },
  { id: 'star-install', name: 'Install Star', sortable: true, numeric: false },
  { id: 'star-rolls', name: 'Star Rolls', sortable: true, numeric: true },
  { id: 'due-date', name: 'Due Date' },
  { id: 'ready', name: 'ready to ship' },
  { id: '_blank', name: '', size: 'xsmall-header' },
];

const TableHeaders3 = [
  { id: 'upload', name: 'Uploaded', sortable: true, numeric: false },
  { id: 'file', name: 'File', sortable: true, numeric: false },
];

const SearchOptions = [
  { id: 'kiosk', name: 'Kiosk', pidx: 1 },
  { id: 'tech', name: 'Technician', pidx: 7 },
];

const SearchOptions2 = [
  { id: 'kiosk', name: 'Kiosk', pidx: 3 },
  { id: 'tech', name: 'Technician', pidx: 4 },
];

const TableTabs = [
  { id: 'open', name: 'open', filter: 'open' },
  { id: 'upcoming', name: 'upcoming', filter: 'upcoming' },
  { id: 'uploads', name: 'uploads', filter: 'uploads' },
];

const MenuOptions = [
  'Mark as Completed',
  'Input/Adjust ETA',
  'Resend Reminder',
  'Assign to Another Tech',
  'Print Paper Letter',
  'Email Paper Letter to Tech',
];

const JobOptions = ['Edit', 'Remove'];

const todays_date = new Date();
const year_list = Array(YEARS)
  .fill(0)
  .map((year, idx) => todays_date.getFullYear() - idx);

const PaperChange = (props) => {
  const [kiosksWithPaperJobs, setKiosksWithPaperJobs] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [Kiosks, setKiosks] = useState([]);
  const [LastJobID, setLastJobID] = useState('');
  const [Techs, setTechs] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [retrieveData, setRetrieveData] = useState(true);
  const [projections, setProjections] = useState([]);
  const [selected, setSelected] = useState({});
  const [searchVal, setSearchVal] = useState('');
  const [searchParam, setSearchParam] = useState(0);
  const [eolData, setEolData] = useState([]);
  const [assignModal, setAssignModal] = useState(false);
  const [projs, setProjs] = useState([]);
  const [newEOL, setNewEOL] = useState(0);
  const [projSum, setProjSum] = useState([]);
  const [tracking, setTracking] = useState({
    auth: null,
  });
  const [upload, setUpload] = useState({
    type: 'jobs',
    upload_date: DateToYMD(new Date()),
    uploading: false,
  });
  const [form, setForm] = useState({
    id: null,
    kiosk: '',
    tech: '',
    paper: '',
    qty: 0,
    star_install: '',
    star_rolls: 0,
    status: '',
    due_date: DateToYMD(new Date()),
  });
  const [openForm, setOpenForm] = useState(false);
  const [activeTab, setActiveTab] = useState(TableTabs[0].name);
  const [upcoming, setUpcoming] = useState([]);
  const [reminderSending, setReminderSending] = useState([]);
  const { userState, userDispatch } = useCustomContext();
  const [snackbar, setSnackbar] = useState({
    visible: false,
    message: '',
    severity: 'success',
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorIndex, setAnchorIndex] = useState(null);
  const [uploads, setUploads] = useState([]);
  const [updateJob, setUpdateJob] = useState({
    original: null,
    selected: null,
  });

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      RetrieveData(true);
    }

    return () => {
      mounted = false;
    };
  }, [activeTab]);

  useEffect(() => {
    $('#paper-changes').toggleClass('flex-box');
  }, [openForm]);

  useEffect(() => {
    $('#assignTech').toggleClass('flex-box');
  }, [assignModal]);

  const RetrieveData = (_loading) => {
    if (_loading) setLoading(true);
    if (activeTab === TableTabs[0].name) {
      // if open
      $.post('/api/table_data', { table: 'paper_table' }, (res) => {
        if (res.status == 'OK') {
          const [last_job_id, kiosks, techs, paper_jobs] = res.data;
          const filtered_jobs = FilterPaperJobs(paper_jobs, kiosks);
          PaperProjectionFormula()
            .then((res) => {
              setProjections(res);
              setKiosksWithPaperJobs(filtered_jobs);
              setLastJobID(last_job_id);
              setKiosks(kiosks);
              setTechs(techs);
              setOriginalData(paper_jobs);
              setRetrieveData(false);
              setLoading(false);
            })
            .catch((e) => {
              console.error(`ERROR: PaperChange.getPaperTrendByKiosk: ${e} - ${new Date()}`);
              setRetrieveData(false);
              setLoading(false);
            });
        } else {
          setRetrieveData(false);
          setLoading(false);
        }
      });
    } else if (activeTab === TableTabs[1].name) {
      // if upcoming
      socket.emit('getUpcomingPaperChanges', (res) => {
        if (!res) {
          console.error(`ERROR: PaperChange.getUpcomingPaperChanges: There was an issue calling this method`);
          setLoading(false);
        } else {
          setUpcoming(res);
          setLoading(false);
        }
      });
    } else {
      (async () => {
        $.get(
          '/listObjectsV2_aws',
          {
            bucket: 'rti.images',
            delimeter: '/',
            prefix: 'uploaded-paper-changes/paper-upload',
          },
          (res) => {
            if (res.Contents) {
              const data = res.Contents.map((content) => ({
                key: content.Key,
                created: DateToMDY(new Date(content.LastModified)),
                filename: content.Key.split('/')[1],
              })).sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
              setUploads(data);
            }
            setLoading(false);
          }
        );
      })();
    }
  };

  const PaperProjectionFormula = () => {
    return new Promise(async (resolve, reject) => {
      socket.emit('kioskPaperTrends', (res) => {
        if (!res) {
          console.error(
            `ERROR: PaperChange.PaperProjectionFormula.kioskPaperTrends: There was an issue calling this method - ${new Date()}`
          );
          reject(false);
        } else {
          resolve(getPaperProjections(res.trends, res.paper));
        }
      });
    });
  };

  const paperProjections = () => {
    return new Promise(async (resolve, reject) => {
      socket.emit('kioskPaperTrends', (res) => {
        if (!res) {
          console.error(
            `ERROR: PaperChange.paperProjections.kioskPaperTrends: There was an issue calling this method - ${new Date()}`
          );
          reject(false);
        } else {
          resolve(res);
        }
      });
    });
  };

  // the checksum methods are for Kris' projection formula
  const paperCheckSum = (_sticker_year = 0) => {
    return new Promise(async (resolve, reject) => {
      socket.emit('kioskPaperTrendCheckSum', _sticker_year, (res) => {
        if (!res) {
          console.error(
            `ERROR: PaperChange.paperCheckSum.kioskPaperTrendCheckSum: There was an issue calling this method - ${new Date()}`
          );
          reject(false);
        } else {
          resolve(res);
        }
      });
    });
  };

  /**
   * ===================================================
   * DESCRIPTION: paperProjectionDownload
   * ====================================================
   */
  const paperProjectionDownload = async () => {
    const proj = await paperProjections();
    const sticker_year = document.querySelector('#projection-year').value;
    const check_sum = await paperCheckSum(sticker_year);
    const curDate = new Date();

    const trends = Object.entries(proj.trends).map(([key, val]) => {
      const projection = eolProjection(sticker_year, val);
      const currentCount_DM = sticker_year == val.dm1_type ? val.dm1_count : val.dm2_count;

      return {
        Kiosk: key, // returns the Kiosk Number
        Server: val.server_id, // returns the Kiosk Store Location
        Address: val.address, // retuns the Kiosk Address Location
        City: val.city, // returns the Kiosk City
        State: val.state, // returns the Kiosk State Location
        Zip: val.zip, // returns the Kiosk Zip Code
        'Sticker Year': sticker_year, // returns the Curent Kiosk Sticker Year
        '': '',
        [`Jan-${curDate.getMonth() > 0 ? curDate.getFullYear() + 1 : curDate.getFullYear()}`]:
          projection.projections[0],
        [`Feb-${curDate.getMonth() > 1 ? curDate.getFullYear() + 1 : curDate.getFullYear()}`]:
          projection.projections[1],
        [`Mar-${curDate.getMonth() > 2 ? curDate.getFullYear() + 1 : curDate.getFullYear()}`]:
          projection.projections[2],
        [`Apr-${curDate.getMonth() > 3 ? curDate.getFullYear() + 1 : curDate.getFullYear()}`]:
          projection.projections[3],
        [`May-${curDate.getMonth() > 4 ? curDate.getFullYear() + 1 : curDate.getFullYear()}`]:
          projection.projections[4],
        [`Jun-${curDate.getMonth() > 5 ? curDate.getFullYear() + 1 : curDate.getFullYear()}`]:
          projection.projections[5],
        [`Jul-${curDate.getMonth() > 6 ? curDate.getFullYear() + 1 : curDate.getFullYear()}`]:
          projection.projections[6],
        [`Aug-${curDate.getMonth() > 7 ? curDate.getFullYear() + 1 : curDate.getFullYear()}`]:
          projection.projections[7],
        [`Sep-${curDate.getMonth() > 8 ? curDate.getFullYear() + 1 : curDate.getFullYear()}`]:
          projection.projections[8],
        [`Oct-${curDate.getMonth() > 9 ? curDate.getFullYear() + 1 : curDate.getFullYear()}`]:
          projection.projections[9],
        [`Nov-${curDate.getMonth() > 10 ? curDate.getFullYear() + 1 : curDate.getFullYear()}`]:
          projection.projections[10],
        [`Dec-${curDate.getMonth() > 11 ? curDate.getFullYear() + 1 : curDate.getFullYear()}`]:
          projection.projections[11],
        [`JAN-${curDate.getFullYear() + 1}`]: projection.mp1,
        [`FEB-${curDate.getFullYear() + 1}`]: projection.mp2,
        [`MAR-YE-${curDate.getFullYear() + 1}`]: projection.mp3,
        'Test Prints': TEST_PRINTS,
        EOL: projection.eol, // returns the Current Sticker Year End Of Life Sticker Amount
        'Current DM Count': currentCount_DM, // returns the current datamax 1 counts
        'Current Transactions': (val.dm1_type = sticker_year ? currentCount_DM : val.dm1_type),
        'Stickers to Send': projection.final > 0 ? projection.final : 0, // returns the amount of Stickers to send when Kiosk is low on datamax count
        'Extra Stickers': projection.eol < currentCount_DM ? currentCount_DM - projection.eol : projection.eol,
      };
    });
    setProjs(trends);
    setProjSum(check_sum);
    document.getElementById('paper-projections').click();
    document.getElementById('projections-check-sum').click();
  };

  function FilterPaperJobs(jobs, res) {
    const filteredArray = jobs
      .reduce((all_jobs, job) => {
        JSON.parse(job.Kiosks).forEach((obj) => {
          if (obj.KioskStatus == 'Open') {
            all_jobs.push({
              PaperJobID: job.PaperJobID,
              Created: `${job.CreatedDate}`,
              Jobs: obj,
              Tech: job.TempTechnician,
              ID: job.ID,
              TechnicianID: job.TechnicianID,
              ETA: obj.ETA ? obj.ETA : null,
              Confirmed: obj.Confirmed ? obj.Confirmed : null,
            });
          }
        });

        return all_jobs;
      }, [])
      .flatMap((job) => {
        // paper jobs can have more than one paper change at a kiosk.  Filter these out into separate paper changes.
        return job.Jobs.Type.map((obj) => {
          return {
            ID: job.ID,
            ETA: job.ETA ? job.ETA : null,
            PaperJobID: job.PaperJobID,
            Completed: obj.Completed,
            Created: job.Created,
            KioskID: job.Jobs.KioskID,
            ServerID: job.Jobs.ServerID,
            Technician: job.Tech,
            Phone: job.MobileNumber,
            TechnicianID: job.TechnicianID,
            PaperType: obj.PaperType,
            PaperSent: obj.PaperQty,
            Confirmed: job.Confirmed ? job.Confirmed : false,
            PaperCount: '',
            PaperNeeded: '',
            ByDate: new Date(job.Jobs.BeforeDate).toString(),
          };
        });
      })
      .filter((job) => !job.Completed);

    filteredArray.forEach((obj1) => {
      // get paper count depending on the paper type
      res.forEach((obj2) => {
        if (obj1.KioskID == obj2.KioskID) {
          if (obj1.PaperType == obj2.PrinterOneYear) {
            obj1.PaperCount = obj2.PrintCountOne;
          } else if (obj1.PaperType == obj2.PrinterTwoYear) {
            obj1.PaperCount = obj2.PrintCountTwo;
          } else if (obj1.PaperType == 'Star') {
            obj1.PaperCount = obj2.PrintCountCOVR;
          }
        }
      });
    });
    return filteredArray;
  }

  const handleOpenOptions = (event) => {
    const [pref, idx] = event.target.id.split('-');
    setAnchorIndex(idx);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOptions = () => {
    setAnchorEl(null);
    setAnchorIndex(null);
  };

  const handleOptionSelect = (event) => {
    const [job_id, kiosk, paper, opt] = event.target.id.split('-');
    switch (parseInt(opt)) {
      case 0:
        if (userState.user.role) {
          const original = originalData.find((paper_job) => paper_job.PaperJobID === job_id);
          handleCompleteJobs(JSON.parse(original.Kiosks), job_id, kiosk, paper);
          setAnchorEl(null);
          setAnchorIndex(null);
        }
        break;
      case 1:
        setAnchorEl(null);
        setAnchorIndex(null);
        setSelected({ job_id, kiosk_id: kiosk, paper });
        $('#paper-eta').toggleClass('flex-box');
        break;
      case 2:
        const original = originalData.find((paper_job) => paper_job.PaperJobID === job_id);
        const kiosk_info = JSON.parse(original.Kiosks).find((el) => el.KioskID == kiosk);
        const temp = {
          ...original,
          ...kiosk_info,
          PaperType: kiosk_info.Type[0].PaperType,
          ReminderType: 'update',
        };
        handlePaperReminder(temp);
        setAnchorEl(null);
        setAnchorIndex(null);
        break;
      case 3:
        if (userState.user.role) {
          const _job = originalData.find((paper_job) => paper_job.PaperJobID === job_id);
          const _selected = JSON.parse(_job.Kiosks).find((el) => el.KioskID == kiosk);
          setUpdateJob((prevState) => ({
            ...prevState,
            original: _job,
            selected: _selected,
          }));
          toggleModal();
          setAnchorEl(null);
          setAnchorIndex(null);
        }
        break;
      case 4:
        const _original = originalData.find((paper_job) => paper_job.PaperJobID === job_id);

        const pdf_obj = {
          technician: _original.TempTechnician,
          shipping: 'Any',
          job_id: _original.PaperJobID,
          jobs: SeparateJobs(RenderPaperJobsForPDF(JSON.parse(_original.Kiosks))),
          user: userState.user.fullName,
          email: userState.user.email,
        };

        setAnchorEl(null);
        setAnchorIndex(null);

        $.post('/api/send_paper_letters', { pdf_obj: pdf_obj, printing: true }, (res) => {
          if (!res) {
            console.error(`ERROR: there was an issue retrieving this pdf doc`);
          } else {
            alert(`Paper letter was sent to ${pdf_obj.email}.`);
          }
        });

        break;
      case 5:
        const _data = originalData.find((paper_job) => paper_job.PaperJobID === job_id);
        const _techInfo = Techs.find((tech) => tech.TechnicianID === _data.TechnicianID);

        const _pdfData = {
          technician: _data.TempTechnician,
          shipping: 'Any',
          job_id: _data.PaperJobID,
          jobs: SeparateJobs(RenderPaperJobsForPDF(JSON.parse(_data.Kiosks))),
          user: userState.user.fullName,
          email: _techInfo.PersonalEmail,
        };
        setAnchorEl(null);
        setAnchorIndex(null);

        $.post('/api/send_paper_letters', { pdf_obj: _pdfData, printing: false }, (res) => {
          if (!res) {
            console.error(`ERROR there was an issue retrieving this pdf doc`);
          } else {
            alert(`Paper letter was sent to ${_pdfData.email}.`);
          }
        });
        break;
    }
  };

  const handlePaperOptionSelect = (event) => {
    const [id, action] = event.target.id.split('-');
    const data = upcoming.find((job) => job.id == id);
    setAnchorEl(null);
    setAnchorIndex(null);

    switch (action) {
      case 'Edit':
        setForm({
          id: id,
          kiosk: data.kiosk,
          tech: data.tech_name,
          paper: data.paper,
          qty: data.qty,
          star_install: data.star_install,
          star_rolls: data.star_rolls,
          status: data.status,
          due_date: data.due_date.split('T')[0],
        });
        setOpenForm(true);
        break;
      case 'Remove':
        setReminderSending([...reminderSending, id]);
        socket.emit('removeUpcomingPaperChange', id, ({ data, status }) => {
          if (!status) {
            console.error(`ERROR: PaperChange.removeUpcomingPaperChange: There was an issue calling this method`);

            const temp = [...reminderSending];
            temp.splice(temp.indexOf(id), 1);
            setReminderSending(temp);
            setSnackbar((prevState) => ({
              ...prevState,
              visible: true,
              message: `ERROR: There was an issue performing this action.  Please notify the developer of this issue.`,
              severity: 'error',
            }));
          } else {
            const temp = [...reminderSending];
            temp.splice(temp.indexOf(id), 1);
            setReminderSending(temp);
            setUpcoming(data);
            setSnackbar((prevState) => ({
              ...prevState,
              visible: true,
              message: `Success! Job was removed successfully.`,
              severity: 'success',
            }));
          }
        });
        break;
    }
  };

  function getTableData() {
    return kiosksWithPaperJobs
      .map((kiosk, index) => {
        const projection = projections.find((proj) => proj.Kiosk == kiosk.KioskID);

        const runout =
          projection && kiosk.PaperType === projection.printer1_year
            ? projection.dm1_runout
            : projection && kiosk.PaperType === projection.printer2_year
            ? projection.dm2_runout
            : projection && kiosk.PaperType === 'Star'
            ? projection.star_runout
            : DEFAULT_RUNOUT;

        /**
         * DESCRIPTION: Paper Changes OPEN TAB INFORMATION
         * these are the fields the can be edited for the Paper Changes Modal
         * */
        return {
          key: `${kiosk.PaperJobID}-${kiosk.KioskID}-${kiosk.PaperType}`,
          runout: runout,
          count: kiosk.PaperCount >= -10 ? kiosk.PaperCount : DEFAULT_RUNOUT,
          cells: [
            { data: DateToLocale(new Date(kiosk.Created)) },
            { data: kiosk.KioskID },
            { data: kiosk.PaperJobID },
            {
              data: `${kiosk.PaperType} (${kiosk.PaperCount})`,
              color: kiosk.PaperCount <= 40 && kiosk.PaperCount > 30 ? 'yellow' : kiosk.PaperCount <= 30 ? 'red' : null,
            },
            { data: kiosk.PaperSent },
            { data: projection ? projection['Daily Avg Transactions'] : '' },
            {
              data: runout !== DEFAULT_RUNOUT && runout ? runout : runout === 0 ? 1 : '-',
            },
            { data: kiosk.Technician },
            { data: FormatShortDate(kiosk.ByDate) },
            {
              data: kiosk.ETA ? DateToLocale(new Date(kiosk.ETA)) : '-',
              color: kiosk.ETA ? getETAColor(kiosk.ETA) : null,
            },
            {
              data: kiosk.Confirmed ? (
                <MdCheck style={{ color: 'green', fontSize: '2.25rem' }} />
              ) : !reminderSending.includes(`${kiosk.PaperJobID}-${kiosk.KioskID}-${kiosk.PaperType}`) ? (
                <Button variant="outlined" onClick={() => handlePaperReminder(kiosk)}>
                  Send Reminder
                </Button>
              ) : (
                <Spinner style={{ margin: 0 }} size="small" />
              ),
            },
            {
              data: (
                <div>
                  <IconButton id={`option-${index}`} aria-haspopup="true" onClick={handleOpenOptions} size="large">
                    <MdMoreVert id={`option-${index}`} />
                  </IconButton>
                  {index == anchorIndex ? (
                    <Menu
                      id={`selected-menu-op-${index}`}
                      anchorEl={index == anchorIndex ? anchorEl : null}
                      open={index == anchorIndex ? Boolean(anchorEl) : false}
                      onClose={handleCloseOptions}
                    >
                      {MenuOptions.map((opt, idx) => (
                        <MenuItem
                          id={`${kiosk.PaperJobID}-${kiosk.KioskID}-${kiosk.PaperType}-${idx}`}
                          key={opt}
                          onClick={handleOptionSelect}
                        >
                          {opt}
                        </MenuItem>
                      ))}
                    </Menu>
                  ) : null}
                </div>
              ),
            },
          ],
        };
      })
      .sort((a, b) => a.count - b.count || a.runout - b.runout)
      .filter((row) =>
        searchVal && searchParam
          ? row.cells[SearchOptions[searchParam - 1].pidx].data &&
            row.cells[SearchOptions[searchParam - 1].pidx].data.toUpperCase().includes(searchVal.toUpperCase())
          : row
      );
  }

  const getUpcomingData = () => {
    return upcoming
      .map((job, index) => ({
        key: job.id,
        cells: [
          { data: `${job.id}`, type: 'checkbox' },
          { data: job.job_id },
          { data: DateToLocale(new Date(job.created)) },
          { data: job.kiosk },
          { data: job.tech_name },
          { data: job.paper },
          { data: job.qty },
          { data: job.star_install ? 'YES' : '' },
          { data: job.star_rolls },
          { data: DateToLocale(new Date(job.due_date)).split(' ')[0] },
          {
            data: job.status == 'ship' ? <MdCheckCircleOutline style={{ color: 'green' }} /> : null,
          },
          {
            data: !reminderSending.includes(`${job.id}`) ? (
              <div>
                <IconButton id={`option-${index}`} aria-haspopup="true" onClick={handleOpenOptions} size="large">
                  <MdMoreVert id={`option-${index}`} />
                </IconButton>
                {index == anchorIndex ? (
                  <Menu
                    id={`selected-menu-op-${index}`}
                    anchorEl={index == anchorIndex ? anchorEl : null}
                    open={index == anchorIndex ? Boolean(anchorEl) : false}
                    onClose={handleCloseOptions}
                  >
                    {JobOptions.map((opt) => (
                      <MenuItem id={`${job.id}-${opt}`} key={opt} onClick={handlePaperOptionSelect}>
                        {opt}
                      </MenuItem>
                    ))}
                  </Menu>
                ) : null}
              </div>
            ) : (
              <Spinner style={{ margin: 0 }} size="small" />
            ),
          },
        ],
      }))
      .filter((row) =>
        searchVal && searchParam
          ? row.cells[SearchOptions2[searchParam - 1].pidx].data &&
            row.cells[SearchOptions2[searchParam - 1].pidx].data.toUpperCase().includes(searchVal.toUpperCase())
          : row
      );
  };

  const getUploads = () => {
    return uploads.map((upload) => ({
      key: upload.key,
      cells: [
        { data: upload.created },
        {
          data: (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <MdDescription style={{ color: 'green' }} />
              <a style={{ textDecoration: 'underline', marginLeft: 12 }} href={`${BASE_URL}${upload.filename}`}>
                {upload.filename}
              </a>
            </div>
          ),
        },
      ],
    }));
  };

  const getETAColor = (eta) => {
    const diff = (new Date(eta).getTime() - new Date().getTime()) / 1000 / 60;
    return diff <= 30 ? 'red' : 'green';
  };

  const handlePaperReminder = (job) => {
    const key = `${job.PaperJobID}-${job.KioskID}-${job.PaperType}`;
    setReminderSending([...reminderSending, key]);
    const msg = job.ReminderType
      ? getReminderMsg(userState.user.fullName, job, 'update')
      : getReminderMsg(userState.user.fullName, job, 'urgent');

    $.post('/api/send_message_async', { messages: msg }, (res) => {
      if (res && res.status === 200) {
        const temp = [...reminderSending];
        temp.splice(temp.indexOf(key), 1);
        setReminderSending(temp);
        setSnackbar((prevState) => ({
          ...prevState,
          visible: true,
          message: 'Success! Message was sent.',
          severity: 'success',
        }));
      } else {
        console.error(res.msg);
        setSnackbar((prevState) => ({
          ...prevState,
          visible: true,
          message: `ERROR: Message failed to send: ${res.msg}`,
          severity: 'error',
        }));
      }
    });
  };

  const downloadData = () => {
    return kiosksWithPaperJobs.map((kiosk) => {
      const _projs = projections.find((proj) => proj.Kiosk == kiosk.KioskID);

      const runout =
        _projs && kiosk.PaperType == _projs.printer1_year
          ? _projs.dm1_runout
          : _projs && kiosk.PaperType == _projs.printer2_year
          ? _projs.dm2_runout
          : _projs && kiosk.PaperType == 'star'
          ? _projs.star_runout
          : DEFAULT_RUNOUT;

      return {
        Kiosk: kiosk.KioskID,
        Server: _projs ? _projs.Server : '',
        Address: _projs ? _projs.address : '',
        City: _projs ? _projs.city : '',
        State: _projs ? _projs.state : '',
        Zip: _projs ? _projs.zip : '',
        'Job #': kiosk.PaperJobID,
        Paper: kiosk.PaperType,
        'Current Counts': kiosk.PaperCount,
        'Daily Avg': _projs && _projs['Daily Avg Transactions'].length ? _projs['Daily Avg Transactions'] : '',
        'Runout (days)': runout !== DEFAULT_RUNOUT && runout ? `${runout}` : runout === 0 ? '1' : '',
        Technician: kiosk.Technician,
        'Due Date': kiosk.ByDate.split('T')[0],
      };
    });
  };

  const handleTransactionUpload = () => {
    const file = document.getElementById('file-upload').files[0];
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        results.data.pop();
        const [year, month] = upload.upload_date.split('-');
        setUpload((prevState) => ({ ...prevState, uploading: true }));

        socket.emit('uploadStickerTransactions', { month: parseInt(month), year }, results.data, (res) => {
          if (!res) {
            try {
              console.error(`ERROR: PaperChange.uploadStickerTransactions - ${new Date()}`);
              setUpload((prevState) => ({
                ...prevState,
                uploading: false,
              }));
            } catch (error) {
              console.error(`ERROR: PaperChange.uploadStickerTransactions: ${error} - ${new Date()}`);
            }
          } else {
            setUpload((prevState) => ({
              ...prevState,
              uploading: false,
            }));
            setSnackbar((prevState) => ({
              ...prevState,
              visible: true,
              message: `Success! Transactions have been uploaded.`,
              severity: 'success',
            }));
          }
        });
      },
    });
  };

  /**
   * DESCRIPTION: PAPER UPLOAD EMAIL REPORT
   * @param {object} filename - paper upload object
   */
  const ParseCSV = () => {
    const file = document.getElementById('file-upload').files[0];
    const today = new Date();
    // Paper Upload File
    const filename = `paper-upload-${DateToYMD(today)}-${today.getTime()}.csv`;
    if (file) {
      setUpload((prevState) => ({ ...prevState, uploading: true }));

      Papa.parse(file, {
        header: true,
        transformHeader: function (h) {
          return h.trim();
        },
        complete: async (results) => {
          results.data.pop(); // get rid of undefined last element
          const valid = validateFile(results.meta.fields);

          if (valid) {
            let data = results.data.map((res) => {
              // dm1_year = if (fixed Install DM1) is true ? return DM1 Year : else NULL
              const dm1_year = res[FILE_HEADERS[7]] || res[FILE_HEADERS[11]] ? res[FILE_HEADERS[5]] : null;

              // dm2_year = if (fixed install DM2) is true ? return DM2 Year : else NULL
              const dm2_year = res[FILE_HEADERS[10]] || res[FILE_HEADERS[12]] ? res[FILE_HEADERS[8]] : null;

              let dm_or_star =
                !res[FILE_HEADERS[7]] &&
                !res[FILE_HEADERS[10]] &&
                !res[FILE_HEADERS[11]] &&
                !res[FILE_HEADERS[12]] &&
                res[FILE_HEADERS[3]] &&
                res[FILE_HEADERS[4]]
                  ? res[FILE_HEADERS[4]] // IF there is no value for fixed or estimated install for either sticker printer, it must be Star
                  : [dm1_year, dm2_year].filter((el) => el);

              if (typeof dm_or_star === 'string' || dm_or_star instanceof String) {
                dm_or_star = ['Star'];
              }
              let dm_or_star_qty =
                !res[FILE_HEADERS[7]] &&
                !res[FILE_HEADERS[11]] &&
                !res[FILE_HEADERS[10]] &&
                !res[FILE_HEADERS[12]] &&
                res[FILE_HEADERS[3]] &&
                res[FILE_HEADERS[4]]
                  ? STAR_ROLL
                  : [res[FILE_HEADERS[7]], res[FILE_HEADERS[10]], res[FILE_HEADERS[11]], res[FILE_HEADERS[12]]].filter(
                      (el) => (el.trim() !== '' ? el : 0)
                    );

              dm_or_star = !dm_or_star.length ? ['Star'] : dm_or_star;
              dm_or_star_qty = !dm_or_star_qty.length ? [STAR_ROLL] : dm_or_star_qty;

              return {
                job_num: 0,
                kiosk: res[FILE_HEADERS[0]].trim(), // Kiosk ID
                tech_id: res[FILE_HEADERS[1]].trim(), // Tech ID
                paper: dm_or_star,
                qty: dm_or_star_qty,
                star_install: res[FILE_HEADERS[3]] ? 1 : 0,
                star_rolls: res[FILE_HEADERS[4]] && res[FILE_HEADERS[3]] ? res[FILE_HEADERS[4]] : 0,
                due_date: `${FormatDateToYMD(res[FILE_HEADERS[2]].trim())} 00:00:00`,
              };
            });

            let idx = 1;
            let found = false;

            const uniq = data.reduce((result, job) => {
              if (!result[job.tech_id]) {
                result[job.tech_id] = idx;
                idx++;
              }
              return result;
            }, {});

            data.forEach((job) => {
              if (uniq[job.tech_id]) {
                job.job_num = uniq[job.tech_id];
              }
            });

            const additions = [];
            const removable = [];

            data.forEach((job, idx) => {
              if (job.paper && Array.isArray(job.paper) && job.paper.length > 1) {
                // check if there are more than one type of paper
                removable.push(idx);
                for (let i = 0; i < job.paper.length; i++) {
                  const type = job.paper[i];
                  const qtyIdx = i < job.qty.length ? i : 0; // Using 0 if qtyIdx exceeds the length of job.qty
                  const qty = job.qty[qtyIdx];
                  additions.push({
                    ...job,
                    paper: type,
                    qty: qty,
                    star_install: i > 0 ? 0 : job.star_install,
                    star_rolls: i > 0 ? 0 : job.star_rolls,
                  });
                }
              } else if (job.paper && Array.isArray(job.paper) && job.paper.length == 1) {
                // there is only one type of paper
                job.paper = job.paper[0];
                job.qty = job.qty[0];
              }
            });

            if (removable.length) {
              data = data.filter((val, idx) => removable.indexOf(idx) == -1);
            }

            if (additions.length) {
              additions.forEach((job) => {
                data.push(job);
              });
            }

            for (let i = 0; i < kiosksWithPaperJobs.length; i++) {
              const _data = data.find(
                (pjob) => pjob.kiosk == kiosksWithPaperJobs[i].KioskID && pjob.paper == kiosksWithPaperJobs[i].PaperType
              );
              if (_data) {
                found = _data;
                break;
              }
            }

            if (!found) {
              axios.post('/api/upload_aws', {
                bucket: 'rti.images', // Valid bucket name without slashes
                key: 'uploaded-paper-changes/' + filename, // Specify the folder structure in the Key
                body: Papa.unparse(results.data),
                contentType: 'text/csv',
              })
                .then((res) => {
                  socket.emit('createUpcomingPaperChanges', JSON.stringify(data), ({ data, status }) => {
                    if (!status.serverStatus) {
                      console.error(`PaperChange.createUpcomingPaperChanges: There was an issue calling this method`);
                      setUpload((prevState) => ({
                        ...prevState,
                        uploading: false,
                      }));
                      setSnackbar((prevState) => ({
                        ...prevState,
                        visible: true,
                        message: `ERROR: There was an issue performing this action.  Please notify the developer of this issue.`,
                        severity: 'error',
                      }));
                    } else {
                      // send upload file to accounting.
                      const attachment = {
                        filename: filename,
                        path: `${BASE_URL}${filename}`,
                      };
                      const msg = `<div>
                          <h1 style="text-align:center;">
                            Paper Change Upload - ${DateToYMD(today)}
                          </h1>
                          <h3 style="text-align:center;">
                            Paper changes have been uploaded.  See attached file for your reference.
                          </h3>
                          ${getEmailSignature('Auto Message')}
                        </div>`;
                      // change to dispatch@registration-technology.com
                      // from accounting@registration-technology.com
                      $.get(
                        '/sendRaw',
                        {
                          to: 'dispatch@registration-technology.com',
                          subject: `Paper Change Upload - ${DateToYMD(today)}`,
                          text: msg,
                          data: attachment,
                        },
                        (res) => {
                          if (res.includes('250')) {
                            setUpload((prevState) => ({
                              ...prevState,
                              uploading: false,
                            }));
                            setSnackbar((prevState) => ({
                              ...prevState,
                              visible: true,
                              message: `Success! You can find these jobs displayed in the 'Upcoming' tab of this page.`,
                              severity: 'success',
                            }));
                            RetrieveData(true);
                          } else {
                            setUpload((prevState) => ({
                              ...prevState,
                              uploading: false,
                            }));
                            setSnackbar((prevState) => ({
                              ...prevState,
                              visible: true,
                              message: `ERROR: There was an error notifying accounting of this upload. ${res}.`,
                              severity: 'error',
                            }));
                          }
                        }
                      );
                    }
                  });
                })
                .catch((e) => {
                  console.error(`ERROR: PaperChange.api.upload_aws: ${e} - ${new Date()}`);
                  setUpload((prevState) => ({
                    ...prevState,
                    uploading: false,
                  }));
                  setSnackbar((prevState) => ({
                    ...prevState,
                    visible: true,
                    message: `SERVER ERROR: ${e}`,
                    severity: 'error',
                  }));
                });
            } else {
              setSnackbar((prevState) => ({
                ...prevState,
                visible: true,
                message: `WARNING: There is already a job created for kiosk ${found.kiosk} for ${found.paper} paper.\n\nPlease remove this kiosk from your upload and try again.`,
                severity: 'warning',
              }));
            }
          } else {
            setUpload((prevState) => ({ ...prevState, uploading: false }));
            setSnackbar((prevState) => ({
              ...prevState,
              visible: true,
              message: `Oops!  It looks like one or more headers in the file that you are trying to upload does not match our criteria or is missing. Please try again.`,
              severity: 'warning',
            }));
          }
        },
      });
    } // if file object is true
    else {
      setSnackbar((prevState) => ({
        ...prevState,
        visible: true,
        message: `Oops!  It looks like you forgot to select a file for upload.  Please try again.`,
        severity: 'warning',
      }));
    } // if file object is not true
  };

  // checks if the headers contained in the file are all present
  const validateFile = (_fields = []) => {
    const numFields = FILE_HEADERS.length;
    // eliminate empty fields before validating
    return (
      _fields
        .filter((field) => field && field.trim())
        .reduce((numValid, header) => {
          return FILE_HEADERS.find((fh) => fh == header) ? numValid + 1 : numValid;
        }, 0) <= numFields
    );
  };

  function FilteredKioskInfo(list = []) {
    const cur_year = new Date().getFullYear();

    return list.reduce((all_jobs, paper_job) => {
      const kiosk = Kiosks.find(({ KioskID }) => KioskID === paper_job.kiosk.toUpperCase());
      const star_install = paper_job.star_install ? 1 : 0;
      const star_extra = paper_job.star_rolls ? parseInt(paper_job.star_rolls) : 0;
      const init_paper_obj = {
        job_id: paper_job.job_id,
        tech: paper_job.tech_name.replace(/\r/g, ''),
        tech_id: paper_job.tech_id,
        kiosk_id: kiosk.KioskID,
        server_id: kiosk.ServerID.replace(/\'/g, '_'),
        store_name: kiosk.StoreName.replace(/\'/g, '_'),
        store_address: kiosk.StoreAddress.replace(/\'/g, '_'),
        city: kiosk.StoreCity.replace(/\'/g, '_'),
        before: FormatPaperDate(paper_job.due_date),
        after: FormatPaperDate(paper_job.due_date),
        paper_type:
          paper_job.paper.trim() === '1' || paper_job.paper.trim() === '2'
            ? (paper_job.paper = 'Star')
            : paper_job.paper,
        star_rolls: Math.abs(star_extra),
        star_install: star_install,
        paper_qty: paper_job.paper.trim() === '1' || paper_job.paper.trim() === '2' ? 690 : paper_job.qty,
        status: 'Open',
        pull: paper_job.Pull == 'x' || paper_job.Pull == 'X' ? true : false,
        new_year: parseInt(paper_job.paper) - cur_year >= 2 ? true : false,
        splice: false,
      };
      if (star_install && paper_job.paper.toLowerCase() != 'star') {
        // there is star paper to be installed with stickers

        all_jobs.push(init_paper_obj);

        const star_obj = {
          ...init_paper_obj,
          paper_type: 'Star',
          paper_qty: STAR_ROLL,
          new_year: false,
        };

        all_jobs.push(star_obj);
      } else if (!star_install && paper_job.paper.toLowerCase() != 'star') {
        all_jobs.push(init_paper_obj);
      } else {
        // regular star paper job
        const star_obj = {
          ...init_paper_obj,
          paper_type: 'Star',
          paper_qty: STAR_ROLL,
          new_year: false,
        };

        all_jobs.push(star_obj);
      }
      return all_jobs;
    }, []);
  }

  function ParsePaperJobs(list) {
    const compressed_jobs = list.reduce((arr, paper_job) => {
      if (arr[paper_job.tech]) {
        arr[paper_job.tech].PaperList = [...arr[paper_job.tech].PaperList, paper_job];
      } else {
        const { MobileNumber = '', Shipping = 'Any' } = Techs.find(
          ({ TechnicianID }) => TechnicianID == paper_job.tech_id
        );
        arr[paper_job.tech] = {
          Tech: paper_job.tech,
          TechID: paper_job.tech_id,
          Shipping: Shipping,
          MobileNumber: MobileNumber,
          PaperList: [paper_job],
        };
      }
      return arr;
    }, {});

    const results = Object.entries(compressed_jobs).map(([key, value], index) => {
      // iterate through paper jobs list and format for creation
      let addjob = true;
      let new_year = false;
      let messagesSent = [{ Name: key, Number: value.MobileNumber, TechList: 'PaperChanger' }];

      const pjob = value.PaperList.reduce(
        (jobs, paperObj) => {
          if (jobs.all_jobs.length) {
            jobs.all_jobs.forEach((pobj) => {
              // iterate through paper job objects
              if (pobj.KioskID == paperObj.kiosk_id) {
                // is duplicate job with different paper type, only push new type to object
                pobj.Type.push({
                  Pull: paperObj.pull,
                  Splice: paperObj.splice,
                  PaperQty: paperObj.paper_qty,
                  PaperType: paperObj.paper_type,
                  Completed: false,
                });
                pobj.StarInstall = paperObj.star_install && !pobj.StarInstall ? true : pobj.StarInstall;
                pobj.StarRolls = paperObj.star_rolls && !pobj.StarRolls ? paperObj.star_rolls : pobj.StarRolls;
                pobj.NewYear = paperObj.new_year ? true : pobj.NewYear;
                addjob = false;

                if (paperObj.paper_type != 'Star') {
                  jobs.paper_count += Math.abs(paperObj.star_rolls) + 1;
                }
              }
            });
          }
          if (paperObj.new_year) {
            new_year = true;
          }

          if (addjob) {
            // job was not a duplicate, push to job list
            if (paperObj.paper_type != 'Star') {
              jobs.paper_count += Math.abs(paperObj.star_rolls) + 1;
            } else if (paperObj.paper_type == 'Star' && paperObj.star_rolls) {
              jobs.paper_count += Math.abs(paperObj.star_rolls);
            }

            jobs.all_jobs.push({
              KioskID: paperObj.kiosk_id,
              ServerID: paperObj.server_id,
              AfterDate: paperObj.after,
              BeforeDate: paperObj.before,
              StarRolls: paperObj.star_rolls,
              StarInstall: paperObj.star_install ? true : false,
              StoreName: paperObj.store_name,
              StoreAddress: paperObj.store_address,
              City: paperObj.city,
              NewYear: new_year,
              KioskStatus: 'Open',
              Type: [
                {
                  Pull: paperObj.pull,
                  Splice: paperObj.splice,
                  PaperQty: paperObj.paper_qty,
                  PaperType: paperObj.paper_type,
                  Completed: false,
                },
              ],
            });
            jobs.startDate = paperObj.after;
            jobs.endDate = paperObj.before;
            jobs.job_id = paperObj.job_id;
            jobs.kiosk_list += !jobs.kiosk_list
              ? `${paperObj.kiosk_id} (${paperObj.server_id})`
              : `, ${paperObj.kiosk_id} (${paperObj.server_id})`;
            addjob = true; // reset boolean
          }

          addjob = true; // reset value
          return jobs;
        },
        {
          all_jobs: [],
          kiosk_list: '',
          startDate: '',
          endDate: '',
          paper_count: 0,
        }
      );

      return {
        Technician: key,
        MessagesSentTo: JSON.stringify(messagesSent),
        Shipping: value.Shipping ? value.Shipping : 'Any',
        PaperJobID: pjob.job_id,
        JobStatus: 'Awaiting Response',
        Dispatcher: userState.user.fullName,
        KiosksString: pjob.kiosk_list,
        StartDate: pjob.startDate,
        EndDate: pjob.endDate,
        TotalPaper: pjob.paper_count,
        Kiosks: JSON.stringify(pjob.all_jobs),
      };
    });

    return results;
  }

  const CreatePaperJobs = () => {
    const x = document.getElementsByName('selected-table-checkbox');
    const selected = [];

    const _checkedAll = document.getElementsByName('table-all-check')[0].checked;
    if (!_checkedAll) {
      // not all jobs have been checked
      for (let i = 0; i < x.length; i++) {
        if (x[i].checked) {
          selected.push(parseInt(x[i].id));
        }
      }
    } else {
      // all jobs have been checked
      upcoming.forEach((job) => {
        selected.push(parseInt(job.id));
      });
    }

    if (selected.length) {
      // get trailing jobs that were selected from the previous page of the table
      const last_val = upcoming.find((job) => job.id == selected[selected.length - 1]);
      const trailing = upcoming
        .filter((job) => job.tech_name == last_val.tech_name)
        .filter((job) => !selected.includes(job.id));

      if (trailing.length) {
        trailing.forEach((job) => {
          selected.push(parseInt(job.id));
        });
      }

      const upcoming_changes = upcoming.filter((el) => selected.includes(el.id)).map((el) => el.id);
      const filtered_data = FilteredKioskInfo(upcoming.filter((el) => selected.includes(el.id)));
      const jobs = ParsePaperJobs(filtered_data);

      if (jobs.length) {
        if (
          confirm(
            `Please make sure that you've printed the letters for all jobs selected before continuing. Click CANCEL to go back.`
          )
        ) {
          socket.emit('insertPaperJob2', jobs, (boolean) => {
            if (!boolean) {
              setUpload((prevState) => ({ ...prevState, uploading: false }));
              setSnackbar((prevState) => ({
                ...prevState,
                visible: true,
                message: 'There was a problem inserting these paper jobs',
                severity: 'error',
              }));
            } else {
              SendPaperLetters(jobs, upcoming_changes); // used to email paper letters to techs and admins
              SendPaperMessages(jobs);
            }
          });
        } else {
          setUpload((prevState) => ({ ...prevState, uploading: false }));
        }
      } else {
        setSnackbar((prevState) => ({
          ...prevState,
          visible: true,
          message: `Warning: There are no jobs selected to create. Please select the jobs you want to create and try again.`,
          severity: 'warning',
        }));
      }
    } else {
      setSnackbar((prevState) => ({
        ...prevState,
        visible: true,
        message: `Warning: You must select a job to create.`,
        severity: 'warning',
      }));
    }
  };

  function SendPaperLetters(paper_jobs, changes) {
    setUpload((prevState) => ({
      ...prevState,
      uploading: true,
    }));

    const update_paper = upcoming
      .filter((job) => changes.includes(job.id))
      .map((job) => ({
        id: job.id,
        kiosk: job.kiosk,
        tech_id: job.tech_id,
        paper: job.paper,
        qty: job.qty,
        star_install: job.star_install,
        star_rolls: job.star_rolls,
        due_date: job.due_date,
        status: 'complete',
      }));

    socket.emit('updateUpcomingPaperChange', JSON.stringify(update_paper), true, ({ data, status }) => {
      if (!status) {
        console.error(`PaperChange.updateUpcomingPaperChange: There was an issue calling this method`);
        setUpload((prevState) => ({
          ...prevState,
          uploading: false,
        }));
        setSnackbar((prevState) => ({
          ...prevState,
          visible: true,
          message: `ERROR: There was an issue performing this action.  Please notify the developer of this issue.`,
          severity: 'error',
        }));
      } else {
        setUpcoming(data);
        setUpload((prevState) => ({
          ...prevState,
          uploading: false,
        }));
        setSnackbar((prevState) => ({
          ...prevState,
          visible: true,
          message: `Success! Changes saved`,
          severity: 'success',
        }));
      }
    });
  }

  function PrepareTracking(res) {
    let tracking = [];
    let today = new Date();
    let [date] = today.toISOString().split('T');
    let itemsReceived = [];

    res.forEach((obj) => {
      JSON.parse(obj.Kiosks).forEach((kioskObj) => {
        let paperList = [];

        if (kioskObj.Type.length > 1) {
          // more than one paper change at this location
          kioskObj.Type.forEach((type) => {
            let paper = {
              ID: type.PaperType,
              Qty: type.PaperQty,
            };

            paperList.push(paper);
          });
        } else if (kioskObj.Type.length == 1) {
          // only one paper change at this location
          let paper = {
            ID: kioskObj.Type[0].PaperType,
            Qty: kioskObj.Type[0].PaperQty,
          };

          paperList.push(paper);
        }

        let newObj = {
          KioskID: kioskObj.KioskID,
          JobID: obj.PaperJobID,
          Type: 'Paper',
          ItemsSent: JSON.stringify(paperList),
          DateSent: date,
          ItemsReceived: JSON.stringify(itemsReceived),
          DateReceived: '',
          ReceivedStatus: 'Pending',
          Technician: obj.Technician,
          Reason: 'Paper Change',
          Results: '',
        };
        tracking.push(newObj);
      });
    });

    return tracking;
  }

  function SendPaperMessages(res) {
    const messages = res.map((obj) => {
      let pullPaper = '';

      JSON.parse(obj.Kiosks).forEach((change) => {
        change.Type.forEach((type) => {
          if (type.Pull)
            pullPaper += '\n Pull ' + type.PaperQty + ' ' + type.PaperType + 's from Kiosk ' + change.KioskID + '.';
        });
      });

      const phone = JSON.parse(obj.MessagesSentTo)[0].Number;

      return {
        body:
          'Hi ' +
          obj.Technician +
          ',\n' +
          'A Paper Change Job Has Been Requested at Kiosk(s):\n' +
          obj.KiosksString +
          '\n' +
          'JobID: ' +
          obj.PaperJobID +
          '\n' +
          'Between: ' +
          FormatDate(obj.StartDate) +
          ' - ' +
          FormatDate(obj.EndDate) +
          '\n' +
          pullPaper +
          '\n\n' +
          'Reply with JobID, then your response. \n' +
          'Example: ' +
          obj.PaperJobID +
          ' Yes \n' +
          'Thank You!',
        to: phone,
        type: 'paper-change',
        from: userState.user.fullName,
        time: CurrentTime(),
      };
    });

    // send all messages to technicians
    $.post(`/api/send_message`, { messages: messages }, (res) => {});
    setSnackbar((prevState) => ({
      ...prevState,
      visible: true,
      message:
        'All paper jobs have been created.  Technicians will begin receiving paper letters and confirmation messages shortly.',
      severity: 'success',
    }));
  }

  function FormatPaperDate(time) {
    return time.split('T')[0];
  }

  const onPrintLabels = (_type) => {
    const _checkedAll = document.getElementsByName('table-all-check')[0].checked;
    const x = document.getElementsByName('selected-table-checkbox');
    const selected = [];
    if (!_checkedAll) {
      // not all jobs have been checked
      for (let i = 0; i < x.length; i++) {
        if (x[i].checked) {
          selected.push(parseInt(x[i].id));
        }
      }
    } else {
      // all jobs have been checked
      upcoming.forEach((job) => {
        selected.push(parseInt(job.id));
      });
    }

    if (selected.length) {
      // get trailing jobs that were selected from the previous page of the table
      if (_type == 'outlabels') {
        const last_val = upcoming.find((job) => job.id == selected[selected.length - 1]);
        const trailing = upcoming
          .filter((job) => job.tech_name == last_val.tech_name)
          .filter((job) => !selected.includes(job.id));

        if (trailing.length) {
          trailing.forEach((job) => {
            selected.push(parseInt(job.id));
          });
        }

        const filtered_data = FilteredKioskInfo(upcoming.filter((el) => selected.includes(el.id)));

        const jobs = ParsePaperJobs(filtered_data);

        if (jobs.length) {
          setPrinting(true);
          const labels = jobs
            .map((job) =>
              ParsePaperForAllLabels(JSON.parse(job.Kiosks), job.Technician, job.PaperJobID, job.TotalPaper)
            )
            .flatMap((obj) => obj);
          const total_sheets = Math.ceil(labels.length / FULL_SHEET);

          let final_sheet = [];
          if (total_sheets) {
            // break labels into sheets of 10
            for (let i = 0; i < total_sheets; i++) {
              const temp = labels.length > FULL_SHEET ? labels.splice(0, FULL_SHEET) : [...labels];
              final_sheet = [...final_sheet, temp];
            }
          }

          $.post(
            '/api/send_paper_labels',
            {
              labels: final_sheet,
              recipient: userState.user.email,
              type: 'outlabels',
            },
            (res) => {
              if (res.status == 200) {
                setPrinting(false);
                setSnackbar((prevState) => ({
                  ...prevState,
                  visible: true,
                  message: 'Success! Labels have been sent to your email to print.',
                  severity: 'success',
                }));
              }
            }
          );
        } else {
          setSnackbar((prevState) => ({
            ...prevState,
            visible: true,
            message: 'Warning: Please select the jobs you would like to print labels for.',
            severity: 'warning',
          }));
        }
      } else if (_type == 'precut') {
        const filtered_data = upcoming.filter((el) => selected.includes(el.id) && el.paper.toUpperCase() != 'STAR');
        const _data = parseForLabels(filtered_data, 10, 3);

        if (filtered_data.length) {
          $.post(
            '/api/send_paper_labels',
            { labels: _data, recipient: userState.user.email, type: 'precut' },
            (res) => {
              if (res.status == 200) {
                setPrinting(false);
                setSnackbar((prevState) => ({
                  ...prevState,
                  visible: true,
                  message: 'Success! Labels have been sent to your email to print.',
                  severity: 'success',
                }));
              }
            }
          );
        } else {
          setSnackbar((prevState) => ({
            ...prevState,
            visible: true,
            message: 'Warning: Please select the jobs you would like to print labels for.',
            severity: 'warning',
          }));
        }
      }
    } else {
      setSnackbar((prevState) => ({
        ...prevState,
        visible: true,
        message: 'Warning: A job must be selected to print labels.',
        severity: 'warning',
      }));
    }
  };

  const onPrintLetters = () => {
    const x = document.getElementsByName('selected-table-checkbox');

    const selected = [];

    const _checkedAll = document.getElementsByName('table-all-check')[0].checked;
    if (!_checkedAll) {
      // not all jobs have been checked
      for (let i = 0; i < x.length; i++) {
        if (x[i].checked) {
          selected.push(parseInt(x[i].id));
        }
      }
    } else {
      // all jobs have been checked
      upcoming.forEach((job) => {
        selected.push(parseInt(job.id));
      });
    }

    if (selected.length) {
      // get trailing jobs that were selected from the previous page of the table
      const last_val = upcoming.find((job) => job.id === selected[selected.length - 1]);
      const trailing = upcoming
        .filter((job) => job.tech_name === last_val.tech_name)
        .filter((job) => !selected.includes(job.id));

      if (trailing.length) {
        trailing.forEach((job) => {
          selected.push(parseInt(job.id));
        });
      }

      const filtered_data = FilteredKioskInfo(upcoming.filter((el) => selected.includes(el.id)));
      const jobs = ParsePaperJobs(filtered_data);

      if (jobs.length) {
        setPrinting(true);
        const pdf_objs = jobs.map((job) => {
          return {
            technician: job.Technician,
            shipping: job.Shipping,
            job_id: job.PaperJobID,
            jobs: SeparateJobs(RenderPaperJobsForPDF(JSON.parse(job.Kiosks))),
            user: userState.user.fullName,
            total_paper: job.TotalPaper,
            email: job.PersonalEmail ? job.PersonalEmail : '',
          };
        });

        $.post(
          '/api/send_paper_letters_bulk',
          {
            pdf_objs: pdf_objs,
            printing: true,
            recipient: userState.user.email,
          },
          (res) => {
            if (res.status !== 200) {
              console.error(`ERROR: ${res.msg} - ${new Date()}`);
              setSnackbar((prevState) => ({
                ...prevState,
                visible: true,
                message:
                  'SERVER ERROR: The server encountered an issue when trying to email these paper letters. Please notify the developer of this issue.',
                severity: 'error',
              }));
            } else {
              setPrinting(false);
              setSnackbar((prevState) => ({
                ...prevState,
                visible: true,
                message: 'Success! Paper letters have been sent to your email to print.',
                severity: 'success',
              }));
            }
          }
        );
      } else {
        setSnackbar((prevState) => ({
          ...prevState,
          visible: true,
          message: 'Warning: Please select the jobs you would like to print letters for.',
          severity: 'warning',
        }));
      }
    } else {
      setSnackbar((prevState) => ({
        ...prevState,
        visible: true,
        message: 'Warning: A job must be selected in order to print paper letters.',
        severity: 'warning',
      }));
    }
  };

  const onMarkReadyToShip = () => {
    const x = document.getElementsByName('selected-table-checkbox');
    const selected = [];

    const _checkedAll = document.getElementsByName('table-all-check')[0].checked;
    if (!_checkedAll) {
      // not all jobs have been checked
      for (let i = 0; i < x.length; i++) {
        if (x[i].checked) {
          selected.push(parseInt(x[i].id));
        }
      }
    } else {
      // all jobs have been checked
      upcoming.forEach((job) => {
        selected.push(parseInt(job.id));
      });
    }

    const update_paper = upcoming
      .filter((job) => selected.includes(job.id))
      .map((job) => ({
        id: job.id,
        kiosk: job.kiosk,
        tech_id: job.tech_id,
        paper: job.paper,
        qty: job.qty,
        star_install: job.star_install,
        star_rolls: job.star_rolls,
        due_date: job.due_date,
        status: 'ship',
      }));

    if (update_paper.length) {
      socket.emit('updateUpcomingPaperChange', JSON.stringify(update_paper), true, ({ data, status }) => {
        if (!status) {
          console.error(`PaperChange.updateUpcomingPaperChange: There was an issue calling this method`);
          setSnackbar((prevState) => ({
            ...prevState,
            visible: true,
            message: `ERROR: There was an issue performing this action.  Please notify the developer of this issue.`,
            severity: 'error',
          }));
        } else {
          setUpcoming(data);
          setSnackbar((prevState) => ({
            ...prevState,
            visible: true,
            message: `Success! Changes saved`,
            severity: 'success',
          }));

          const msg = `
                        <span>
                            <h1 style="text-align: center;">Upcoming Paper Changes Ready to Ship</h1>
                            <p>Some upcoming paper changes have been marked as ready to ship.  Please follow the link provided below, and create these paper jobs when necessary.</p>
                            <a href="https://console.ez-tab.net/paper">Click here.</a>
                            ${getEmailSignature('Auto Message')}
                        </span>
                    `;
          const to = `accounting@registration-technology.com`;

          const mailOpts = {
            to: to,
            bcc: 'self',
            subject: `Upcoming Paper Changes Ready to Ship`,
            msg: msg,
          };

          $.post('/api/send_email_async', { email: mailOpts }, (res) => {
            if (res.status != 'success') {
              console.error(`PaperChange.send-email-async: There was an issue sending the ready to ship email.`);
            }
          });
        }
      });
    } else {
      setSnackbar((prevState) => ({
        ...prevState,
        visible: true,
        message: `WARNING: Please select a job for this action.`,
        severity: 'warning',
      }));
    }
  };

  function renderTableButtons() {
    return userState.user.role && activeTab === TableTabs[0].name ? (
      <div style={{ display: 'flex' }}>
        <CSVLink
          style={{ background: 'none', border: 'none' }}
          target="_blank"
          rel="noopener noreferrer"
          className="hidden"
          id="table-download"
          data={downloadData()}
          filename={'paper-changes.csv'}
        ></CSVLink>
        <Button
          className="rti-blue-round"
          variant="contained"
          startIcon={<MdCloudUpload />}
          onClick={() => {
            setUpload((prevState) => ({ ...prevState, type: 'txn' }));
            $('#paper-upload').toggleClass('flex-box');
          }}
        >
          Transactions
        </Button>
        <Button
          className="rti-blue-round"
          variant="contained"
          startIcon={<FiDownload />}
          onClick={() => document.getElementById('table-download').click()}
        >
          Paper Changes
        </Button>
        <Button
          className="rti-blue-round"
          variant="contained"
          onClick={() => $('#paper-projections-modal').toggleClass('flex-box')}
        >
          paper projections
        </Button>
        <Button className="rti-blue-round" variant="contained" onClick={() => $('#code-modal').toggleClass('flex-box')}>
          new kiosk projection
        </Button>
        <CSVLink
          style={{ background: 'none', border: 'none' }}
          target="_blank"
          rel="noopener noreferrer"
          className="hidden"
          id="paper-projections"
          data={projs}
          filename={'paper-projections.csv'}
        ></CSVLink>
        <CSVLink
          style={{ background: 'none', border: 'none' }}
          target="_blank"
          rel="noopener noreferrer"
          className="hidden"
          id="projections-check-sum"
          data={projSum}
          filename={'paper-projections-kml.csv'}
        ></CSVLink>
      </div>
    ) : userState.user.role && activeTab === TableTabs[1].name ? (
      <div style={{ display: 'flex' }}>
        <Button
          className="rti-blue-round"
          variant="contained"
          disabled={printing ? true : false}
          startIcon={<MdPrint />}
          onClick={() => onPrintLabels('precut')}
        >
          {printing ? 'Sending...' : 'Paper Prep Labels'}
        </Button>
        <Button
          className="rti-blue-round"
          variant="contained"
          disabled={printing ? true : false}
          startIcon={<MdPrint />}
          onClick={() => onPrintLabels('outlabels')}
        >
          {printing ? 'Sending...' : 'Out Labels'}
        </Button>
        <Button
          className="rti-blue-round"
          variant="contained"
          disabled={printing ? true : false}
          startIcon={<MdPrint />}
          onClick={onPrintLetters}
        >
          {printing ? 'Sending...' : 'Paper Letters'}
        </Button>
        <Button className="rti-blue-round" variant="contained" startIcon={<MdCheckCircleOutline />} onClick={onMarkReadyToShip}>
          Ready to Ship
        </Button>
        <Button
          className="rti-blue-round"
          variant="contained"
          onClick={() => $('#create-paper-jobs').toggleClass('flex-box')}
        >
          Create Jobs
        </Button>
      </div>
    ) : userState.user.role && activeTab === TableTabs[2].name ? (
      <div style={{ display: 'flex' }}>
        <Button
          className="rti-blue-round"
          variant="contained"
          startIcon={<MdCloudUpload />}
          onClick={() => {
            setUpload((prevState) => ({ ...prevState, type: 'jobs' }));
            $('#paper-upload').toggleClass('flex-box');
          }}
        >
          Paper Changes
        </Button>
      </div>
    ) : null;
  }

  const handleEOLData = async () => {
    setUpload((prevState) => ({ ...prevState, uploading: true }));

    const sticker_year = document.getElementById('eol-year').value;
    const year_dif = parseInt(sticker_year) - todays_date.getFullYear();
    const increase = year_dif === 1 ? year_dif : 1 + DEFAULT_INCREASE * year_dif;
    const year_start = todays_date.getFullYear();
    const years = Array(YEARS)
      .fill(0)
      .map((year, idx) => year_start - idx);

    try {
      const result = await getStickerEOL(years, parseInt(sticker_year), increase);
      setEolData(result);
    } catch (error) {
      console.error(`ERROR: PaperChange.handleEOLData: ${error} - ${new Date()}`);
      setEolData([]);
    }

    setUpload((prevState) => ({ ...prevState, uploading: false }));
    document.getElementById('outstanding').click();
  };

  const search = (param, searchVal) => {
    setSearchVal(searchVal);
    setSearchParam(param);
  };

  const clearSearch = () => {
    setSearchVal('');
  };

  const handleCompleteJobs = (jobs, job_id, kiosk_id, paper) => {
    let jobs_complete = true;
    const job = jobs.map((paper_job) => {
      let subs_complete = true;
      if (paper_job.KioskID === kiosk_id) {
        paper_job.Type.forEach((type) => {
          if (type.PaperType === paper) {
            type.Completed = true;
          } else if (type.PaperType !== paper && !type.Completed) {
            subs_complete = false;
            jobs_complete = false;
          }
        });
      } else {
        if (paper_job.KioskStatus === 'Open') {
          subs_complete = false;
          jobs_complete = false;
        }
      }

      if (paper_job.KioskStatus === 'Open' && subs_complete) {
        paper_job.KioskStatus = 'Completed';
      }
      return paper_job;
    });

    const paper_job = {
      Jobs: JSON.stringify(job),
      Complete: jobs_complete,
    };

    socket.emit('updatePaperJobStatus', paper_job, job_id, (res) => {
      if (!res) {
        setSnackbar((prevState) => ({
          ...prevState,
          visible: true,
          message: 'There was an error completing this job',
          severity: 'error',
        }));
        console.error(`ERROR: PaperChange.updatePaperJobStatus: There was an issue calling this method`);
      } else {
        RetrieveData(true);
        setSnackbar((prevState) => ({
          ...prevState,
          visible: true,
          message: 'Success! Paper job was completed.',
          severity: 'success',
        }));
      }
    });
  };

  const handleCloseSnack = () => {
    setSnackbar((prevState) => ({
      ...prevState,
      visible: false,
    }));
  };

  const handleUploadDate = (event) => {
    const date = event.target.value;
    setUpload((prevState) => ({ ...prevState, upload_date: date }));
  };

  const handleETA = () => {
    const date = document.getElementById('date-eta').value;
    const time = document.getElementById('time-eta').value;

    const combined = `${date} ${time}:00`;

    socket.emit('confirmPaperChangeRequest', combined, selected.job_id, selected.kiosk_id, (res) => {
      if (!res) {
        console.error(`paper-change.confirmPaperChangeRequest: There was an issue calling this method`);
        setSnackbar((prevState) => ({
          ...prevState,
          visible: true,
          message:
            'ERROR: Sorry, we were unable to complete this request at this time.  Please contact the developer about this issue.',
          severity: 'error',
        }));
      } else {
        RetrieveData(true);
        setSnackbar((prevState) => ({
          ...prevState,
          visible: true,
          message: 'Success! ETA has been added.',
          severity: 'success',
        }));
        $('#paper-eta').toggleClass('flex-box');
      }
    });
  };

  const filterTable = (filter) => {
    setLoading(true);
    setActiveTab(filter);
  };

  const onModalClose = () => {
    setOpenForm(false);
  };

  const onSavePaperChanges = () => {
    const due_date = document.getElementById('paper-due-date').value;
    const kiosk = document.getElementById('kiosk').value;
    const tech = document.getElementById('tech').value;
    const paper_type = document.getElementById('paper-type').value;
    const star_install = document.getElementById('star-install').value;
    const qty = document.getElementById('paper-qty').value;
    const star_rolls = document.getElementById('star-rolls').value;
    const status = document.getElementById('job-status').value;

    if (due_date && kiosk && tech && paper_type && star_install && parseInt(qty)) {
      try {
        const job_obj = {
          id: form.id,
          kiosk: kiosk,
          tech_id: Techs.find((el) => `${el.FirstName} ${el.LastName}` == tech).TechnicianID,
          paper: paper_type,
          qty: qty,
          star_install: star_install == 'Yes' ? 1 : 0,
          star_rolls: star_rolls,
          due_date: `${due_date} 00:00:00`,
          status: status == 'Yes' ? 'ship' : 'active',
        };

        setUpload((prevState) => ({
          ...prevState,
          uploading: true,
        }));

        socket.emit('updateUpcomingPaperChange', JSON.stringify(job_obj), false, ({ data, status }) => {
          if (!status) {
            console.error(`PaperChange.updateUpcomingPaperChange: There was an issue calling this method`);
            setUpload((prevState) => ({
              ...prevState,
              uploading: false,
            }));
            setSnackbar((prevState) => ({
              ...prevState,
              visible: true,
              message: `ERROR: There was an issue performing this action.  Please notify the developer of this issue.`,
              severity: 'error',
            }));
          } else {
            setUpcoming(data);
            setUpload((prevState) => ({
              ...prevState,
              uploading: false,
            }));
            setSnackbar((prevState) => ({
              ...prevState,
              visible: true,
              message: `Success! Changes saved`,
              severity: 'success',
            }));
          }
        });
      } catch (error) {
        setSnackbar((prevState) => ({
          ...prevState,
          visible: true,
          message: `ERROR: ${error}`,
          severity: 'error',
        }));
      }
    } else {
      setSnackbar((prevState) => ({
        ...prevState,
        visible: true,
        message: 'Warning: Please make sure that all fields are filled out before saving, and try again.',
        severity: 'warning',
      }));
    }
  };

  const toggleModal = () => {
    setAssignModal((prevState) => !prevState);
  };

  const onAssignTech = () => {
    const _tech = document.getElementById('tech-to-assign').value;

    if (_tech) {
      const _tech_found = Techs.find(
        (tech) => `${tech.FirstName} ${tech.LastName}`.toUpperCase() == _tech.toUpperCase()
      );
      const _open_job = originalData.find((data) => data.TechnicianID == _tech_found.TechnicianID);

      if (_open_job) {
        // append to current job
        updatePaperJob(_open_job);
      } else {
        // technician does not have an open job currently
        setSnackbar((prevState) => ({
          ...prevState,
          visible: true,
          message:
            'Warning: This technician does not have any open jobs currently. Please delete the job from the current technician and create a new one to be assigned to the next technician.',
          severity: 'warning',
        }));
      }
    } else {
      setSnackbar((prevState) => ({
        ...prevState,
        visible: true,
        message: 'Warning: You must select a technician to assign to this job.',
        severity: 'warning',
      }));
    }
  };

  const updatePaperJob = (_new_job) => {
    const _selected_job = updateJob.selected;
    const _original_job = updateJob.original;
    const _job_copy = JSON.parse(_new_job.Kiosks);
    const _kiosk_copy = `${_new_job.KiosksString}, ${_selected_job.KioskID} (${_selected_job.ServerID}) `;
    _job_copy.push(_selected_job);

    _new_job.Kiosks = JSON.stringify(_job_copy);
    _new_job.KiosksString = _kiosk_copy;

    const _original_copy = JSON.parse(updateJob.original.Kiosks);
    const _original_kiosks = updateJob.original.KiosksString.split(',').map((str) => str.trim());

    let _idx = 0;

    _original_copy.forEach((job, idx) => {
      if (job.KioskID == _selected_job.KioskID) {
        _idx = idx;
      }
    });

    _original_copy.splice(_idx, 1);
    _original_kiosks.splice(_idx, 1);
    const _final_kiosks = _original_kiosks.join(' , ');
    _original_job.Kiosks = _original_copy;
    _original_job.KiosksString = _final_kiosks;

    socket.emit(
      'updatePendingPaperJob',
      _original_job.KiosksString,
      JSON.stringify(_original_job.Kiosks),
      _original_job.ID,
      (res) => {
        if (!res) {
          console.error(
            `SERVER ERROR: PaperChange.updatePendingPaperJob: There was an issue executing this query. Please contact the developer for this issue.`
          );
        } else {
          socket.emit('updatePendingPaperJob', _new_job.KiosksString, _new_job.Kiosks, _new_job.ID, (res) => {
            if (!res) {
              console.error(
                `SERVER ERROR: PaperChange.updatePendingPaperJob: There was an issue executing this query. Please contact the developer for this issue.`
              );
            } else {
              setSnackbar((prevState) => ({
                ...prevState,
                visible: true,
                message: 'Success! This paper job has been reassigned.',
                severity: 'success',
              }));

              RetrieveData(true);
              toggleModal();
            }
          });
        }
      }
    );
  };

  const onCalcProjection = () => {
    setNewEOL(0);
    const _year = document.getElementById('new-eol').value;
    const eol = EOLTransactions(null, null, [], _year, 30);
    setNewEOL(eol);
  };

  return (
    <div className="ViewedContentContainer" id="PaperChangeContainer">
      {/* TODO: go through the entire file; remove & replace all inline styles with css styles */}
      <div style={{ height: '100%' }}>
        <div className="TitleBarContainer">
          <h1>Paper Changes</h1>
        </div>
        <Modal
          modal_id="paper-upload"
          dimension={{ width: 400, height: 400, justifyContent: 'center' }}
          modal_header={upload.type === 'jobs' ? 'Upload Paper Jobs' : 'Upload Sticker Transactions'}
        >
          <div className="paper-upload-inner-modal">
            <FileUpload />
            {!upload.uploading ? (
              <div>
                {upload.type !== 'jobs' ? (
                  <TextField
                    style={{ minWidth: 200 }}
                    id="upload-date"
                    className="table-date-input space-below"
                    label="Upload Date"
                    type="date"
                    value={upload.upload_date}
                    onChange={handleUploadDate}
                  />
                ) : null}
                <div>
                  <Button
                    disabled={upload.uploading}
                    className="rti-blue-round"
                    variant="contained"
                    onClick={upload.type === 'jobs' ? ParseCSV : handleTransactionUpload}
                  >
                    Upload
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <label>Uploading...</label>
                <br></br>
                <LinearProgress />
              </div>
            )}
          </div>
        </Modal>
        {assignModal ? (
          <Modal
            modal_id="assignTech"
            dimension={{ width: 400 }}
            modal_header="Assign to Another Tech"
            onClose={toggleModal}
          >
            <div className="flex-column flex-align-center">
              <p>
                Select the technician you want to assign this paper change to, then click <b>Assign</b>
              </p>
              <div className="input-block">
                <label className="input-label">Technician</label>
                <div className="search-by">
                  <input
                    style={{ margin: 0, width: 200 }}
                    id="tech-to-assign"
                    list="tech-list"
                    name="tech-list-dropdown"
                    placeholder="Search Techs..."
                  ></input>
                  <datalist id="tech-list">
                    {Techs.map((tech) => (
                      <option
                        key={tech.TechnicianID}
                        value={`${tech.FirstName} ${tech.LastName}`}
                      >{`${tech.FirstName} ${tech.LastName}`}</option>
                    ))}
                  </datalist>
                </div>
              </div>
              <div className="vert-margin-med">
                <Button className="rti-blue-round" variant="contained" onClick={onAssignTech}>
                  Assign
                </Button>
              </div>
            </div>
          </Modal>
        ) : null}
        {activeTab === TableTabs[1].name ? (
          <Modal modal_id="create-paper-jobs" dimension={{ width: 400 }} modal_header="Create Paper Jobs">
            <div style={{ textAlign: 'center' }}>
              <h3 style={{ margin: 40 }}>
                You are about to create paper jobs for the jobs selected in this table. Make sure all jobs selected are
                correct and letters have been printed before clicking CREATE JOBS below.
              </h3>
              {!upload.uploading ? (
                <div>
                  <Button
                    disabled={upload.uploading}
                    className="rti-blue-round"
                    variant="contained"
                    onClick={CreatePaperJobs}
                  >
                    Create Jobs
                  </Button>
                </div>
              ) : (
                <div>
                  <label>Creating...</label>
                  <br></br>
                  <LinearProgress />
                </div>
              )}
            </div>
          </Modal>
        ) : null}
        {activeTab === TableTabs[0].name ? (
          <Modal modal_id="paper-eta" dimension={{ width: 434 }} modal_header="ETA">
            <div style={{ textAlign: 'center' }}>
              <div style={{ marginBottom: 46 }}>
                <TextField
                  style={{ minWidth: 200 }}
                  id="date-eta"
                  className="table-date-input space-below"
                  label="Date"
                  type="date"
                  defaultValue={DateToYMD(todays_date)}
                />
                <br></br>
                <TextField
                  style={{ minWidth: 200 }}
                  id="time-eta"
                  className="table-date-input space-below"
                  label="Time"
                  type="time"
                  defaultValue={`${todays_date.getHours().toString().padStart('0', 2)}:${todays_date
                    .getMinutes()
                    .toString()
                    .padStart('0', 2)}`}
                />
              </div>
              <span style={{ paddingBottom: 40 }}>
                <Button className="rti-blue-round" variant="contained" onClick={handleETA}>
                  Confirm
                </Button>
              </span>
            </div>
          </Modal>
        ) : null}
        {openForm ? (
          <Modal
            modal_id="paper-changes"
            dimension={{ width: 434 }}
            modal_header="Edit Paper Change"
            onClose={onModalClose}
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <TextField
                  style={{ minWidth: 200 }}
                  id="paper-due-date"
                  className="table-date-input space-below-medium"
                  label="Due Date"
                  type="date"
                  defaultValue={form.due_date}
                />
                <div className="input-block" style={{ marginRight: 40 }}>
                  <label className="input-label">Ready to Ship</label>
                  <div className="search-by">
                    <select style={{ width: 100 }} id="job-status" defaultValue={form.status == 'ship' ? 'Yes' : 'No'}>
                      <option key="yes" value="Yes">
                        Yes
                      </option>
                      <option key="no" value="No">
                        No
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div className="input-block">
                  <label className="input-label">Kiosk</label>
                  <div className="search-by">
                    <input
                      style={{ margin: 0, width: 200 }}
                      id="kiosk"
                      list="kiosk-list"
                      name="kiosk-list-dropdown"
                      defaultValue={form.kiosk}
                      placeholder="Search Kiosks..."
                    ></input>
                    <datalist id="kiosk-list">
                      {Kiosks.map((kiosk) => (
                        <option
                          key={kiosk.KioskID}
                          value={kiosk.KioskID}
                        >{`${kiosk.KioskID} ${kiosk.ServerID}`}</option>
                      ))}
                    </datalist>
                  </div>
                </div>
                <div className="input-block">
                  <label className="input-label">Technician</label>
                  <div className="search-by">
                    <input
                      style={{ margin: 0, width: 200 }}
                      id="tech"
                      list="tech-list"
                      name="tech-list-dropdown"
                      defaultValue={form.tech}
                      placeholder="Search Techs..."
                    ></input>
                    <datalist id="tech-list">
                      {Techs.map((tech) => (
                        <option
                          key={tech.TechnicianID}
                          value={`${tech.FirstName} ${tech.LastName}`}
                        >{`${tech.FirstName} ${tech.LastName}`}</option>
                      ))}
                    </datalist>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 10,
                }}
              >
                <div className="input-block" style={{ marginRight: 40 }}>
                  <label className="input-label">Paper Type</label>
                  <div className="search-by">
                    <select style={{ width: 100 }} id="paper-type" defaultValue={form.paper}>
                      <option key="star" value="Star">
                        Star
                      </option>
                      <option
                        key={`${new Date().getFullYear()}`}
                        value={`${new Date().getFullYear()}`}
                      >{`${new Date().getFullYear()}`}</option>
                      <option key={`${new Date().getFullYear() + 1}`} value={`${new Date().getFullYear() + 1}`}>{`${
                        new Date().getFullYear() + 1
                      }`}</option>
                      <option key={`${new Date().getFullYear() + 2}`} value={`${new Date().getFullYear() + 2}`}>{`${
                        new Date().getFullYear() + 2
                      }`}</option>
                    </select>
                  </div>
                </div>
                <TextField
                  id="paper-qty"
                  className="table-date-input space-below-medium"
                  label="Qty"
                  type="number"
                  defaultValue={form.qty}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 10,
                }}
              >
                <div className="input-block" style={{ marginRight: 40 }}>
                  <label className="input-label">Star Install</label>
                  <div className="search-by">
                    <select style={{ width: 100 }} id="star-install" defaultValue={form.star_install ? 'Yes' : 'No'}>
                      <option key="0" value="No">
                        No
                      </option>
                      <option key="1" value="Yes">
                        Yes
                      </option>
                    </select>
                  </div>
                </div>
                <TextField
                  id="star-rolls"
                  className="table-date-input space-below-medium"
                  label="Star Rolls"
                  type="number"
                  defaultValue={form.star_rolls}
                />
              </div>
              {!upload.uploading ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="text" color="primary" onClick={onSavePaperChanges}>
                    Save Changes
                  </Button>
                </div>
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <label>Saving...</label>
                  <br></br>
                  <LinearProgress />
                </div>
              )}
            </div>
          </Modal>
        ) : null}
        {activeTab === TableTabs[0].name ? (
          <Modal modal_id="end-of-life" dimension={{ width: 434 }} modal_header="Sticker End of Life">
            <div style={{ textAlign: 'center' }}>
              <div className="search-by" style={{ margin: 28 }}>
                <label className="input-label">Sticker Year</label>
                <br></br>
                <div>
                  <select className="search-selection" id="eol-year">
                    <option key="0" value={new Date().getFullYear()}>
                      {new Date().getFullYear()}
                    </option>
                    <option key="1" value={new Date().getFullYear() + 1}>
                      {new Date().getFullYear() + 1}
                    </option>
                    <option key="2" value={new Date().getFullYear() + 2}>
                      {new Date().getFullYear() + 2}
                    </option>
                    <option key="3" value={new Date().getFullYear() + 3}>
                      {new Date().getFullYear() + 3}
                    </option>
                  </select>
                </div>
              </div>
              {!upload.uploading ? (
                <span style={{ paddingBottom: 40 }}>
                  <Button
                    className="rti-blue-round"
                    variant="contained"
                    startIcon={<FiDownload />}
                    onClick={handleEOLData}
                  >
                    Download
                  </Button>
                </span>
              ) : (
                <div>
                  <label>Calculating...</label>
                  <br></br>
                  <LinearProgress />
                </div>
              )}
            </div>
          </Modal>
        ) : null}
        {activeTab === TableTabs[0].name ? (
          <Modal modal_id="code-modal" dimension={{ width: 500 }} modal_header="End of Life Projection for New Kiosks">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="flex-just-even flex-align-center vert-margin text-center">
                <div style={{ margin: 0 }} className="search-by">
                  <label className="input-label">Sticker Year</label>
                  <br></br>
                  <div>
                    <select className="search-selection" id="new-eol">
                      <option key="0" value={new Date().getFullYear()}>
                        {new Date().getFullYear()}
                      </option>
                      <option key="1" value={new Date().getFullYear() + 1}>
                        {new Date().getFullYear() + 1}
                      </option>
                      <option key="2" value={new Date().getFullYear() + 2}>
                        {new Date().getFullYear() + 2}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              {newEOL ? (
                <div>
                  <h2 className="text-center">Projected: {Math.round(newEOL)} Stickers</h2>
                </div>
              ) : null}
              <div style={{ textAlign: 'center' }} className="vert-margin-med">
                <Button className="rti-blue-round" variant="contained" onClick={onCalcProjection}>
                  Calculate
                </Button>
              </div>
            </div>
          </Modal>
        ) : null}
        {activeTab === TableTabs[0].name ? (
          <Modal modal_id="paper-projections-modal" dimension={{ width: 500 }} modal_header="Paper Projection Year">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="flex-just-even flex-align-center vert-margin text-center">
                <div style={{ margin: 0 }} className="search-by">
                  <label className="input-label">Paper Projections Year</label>
                  <br></br>
                  <div>
                    <select className="search-selection" id="projection-year" defaultValue={new Date().getFullYear()}>
                      <option key="0" value={new Date().getFullYear()}>
                        {new Date().getFullYear()}
                      </option>
                      <option key="1" value={new Date().getFullYear() + 1}>
                        {new Date().getFullYear() + 1}
                      </option>
                      <option key="2" value={new Date().getFullYear() + 2}>
                        {new Date().getFullYear() + 2}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              {!upload.uploading ? (
                <span className="flex-just-even flex-align-center vert-margin text-center">
                  <Button
                    className="rti-blue-round"
                    variant="contained"
                    startIcon={<FiDownload />}
                    onClick={paperProjectionDownload}
                  >
                    paper projections download
                  </Button>
                </span>
              ) : null}
            </div>
          </Modal>
        ) : null}
        <AlertBar
          visible={snackbar.visible}
          onClose={handleCloseSnack}
          message={snackbar.message}
          severity={snackbar.severity}
        />
        <CustomTable
          paginate
          rpp={''}
          searchable
          search={search}
          clear_search={clearSearch}
          search_options={SearchOptions}
          loading_data={Loading}
          headers={
            activeTab === TableTabs[0].name
              ? TableHeaders
              : activeTab === TableTabs[1].name
              ? TableHeaders2
              : TableHeaders3
          }
          tabs={TableTabs}
          tab_style="button"
          active_tab={activeTab}
          filter_data={filterTable}
          rows={
            activeTab === TableTabs[0].name
              ? getTableData()
              : activeTab === TableTabs[1].name
              ? getUpcomingData()
              : getUploads()
          }
          table_buttons={renderTableButtons()}
          onRefresh={() => RetrieveData(true)}
        />
      </div>
    </div>
  );
};

export default PaperChange;
